/* global window */

import moment from 'moment-timezone'

export const getMoment = () => moment().toISOString()

export const tag = _tagData => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  return null
  // return window.UST.tag(tagData)
}

export const event = _eventData => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  return null
  // return window.UST.event(eventData)
}

export const enableRecording = () => {
  window.UST.settings.enableSessionRecordings = true
  window.UST.fullRec = true
  window.UST.nativeSessionRecording = true
}
