import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { red, green } from '@mui/material/colors'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Pagination,
  CircularProgress,
  Grid
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import { Button, Input, IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'
import { validDomain } from 'utils/regex'

const useStyles = makeStyles()({
  pagination: {
    display: 'flex',
    marginTop: '1em',
    justifyContent: 'center'
  },
  confirmIcon: {
    color: green[500]
  },
  cancelIcon: {
    color: red[500]
  },
  deleteRow: {
    backgroundColor: red[50]
  }
})

export default function AllowedDomains({
  domains,
  defaultDomains,
  formData,
  query,
  newDomain,
  editMode,
  deleteMode,
  total,
  page,
  loading,
  onCreate,
  onUpdate,
  onDelete,
  onSearch,
  onQueryChange,
  onNewDomainChange,
  toggleEditMode,
  toggleDeleteMode,
  setPage,
  setFormData
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    onSearch()
  }, [])

  const handleOnSearch = () => {
    setPage(1)
    onSearch()
  }

  const handleOnNewDomain = () => {
    onCreate()
  }

  const handleEditDomain = (id, name) => {
    toggleEditMode()
    setFormData({ id, name })
  }

  const handleDeleteDomain = (id, name) => {
    toggleDeleteMode(id)
    setFormData({ id, name })
  }

  const handlePageChange = p => {
    setPage(p)
    onSearch()
  }

  const handleOnConfirm = () => {
    if (editMode) {
      onUpdate()
    }

    if (deleteMode) {
      onDelete()
    }
  }

  const handleOnCancel = () => {
    setFormData({ id: null, name: '' })

    if (editMode) {
      toggleEditMode()
    }

    if (deleteMode) {
      toggleDeleteMode()
    }
  }

  const isDomainActionTaken = id => formData.get('id') === id
  const isValidNewName = () => validDomain(newDomain) && !defaultDomains.some(d => d.get('domain') === newDomain)
  const isValidUpdateName = () => validDomain(formData.get('name')) && formData.get('name') !== ''

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{i18n.get('allowed_sender_email_domains')}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.panelDetails }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Input
              fullWidth
              type="text"
              value={query}
              label={i18n.get('quick_search')}
              onChange={event => onQueryChange(event.target.value)}
              disabled={loading || editMode || deleteMode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title={i18n.get('search')}
                      onClick={() => handleOnSearch()}
                      disabled={loading || editMode || deleteMode}
                    >
                      {loading && (
                        <CircularProgress
                          style={{ position: 'absolute' }}
                          size={34}
                          variant="indeterminate"
                        />
                      )}
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Input
              fullWidth
              type="text"
              value={newDomain}
              error={newDomain !== '' && !isValidNewName()}
              label={i18n.get('new_domain')}
              onChange={event => onNewDomainChange(event.target.value)}
              disabled={loading || editMode || deleteMode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="primary"
                      loading={loading}
                      disabled={!isValidNewName() || editMode || deleteMode}
                      onClick={() => handleOnNewDomain()}
                    >
                      {i18n.get('add')}
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <br />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{i18n.get('domain')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {domains.map((domain, index) => (
                isDomainActionTaken(domain.get('id')) ? (
                  <TableRow
                    key={index}
                    className={deleteMode ? classes.deleteRow : ''}
                  >
                    {editMode ? (
                      <TableCell>
                        <Input
                          error={!isValidUpdateName()}
                          size="small"
                          fullWidth
                          type="text"
                          value={formData.get('name')}
                          onChange={event => setFormData({ id: domain.get('id'), name: event.target.value })}
                          disabled={loading}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>{domain.get('name')}</TableCell>
                    )}
                    <TableCell align="right">
                      <IconButton
                        title={i18n.get('confirm')}
                        onClick={() => handleOnConfirm()}
                        disabled={loading || !isValidUpdateName()}
                      >
                        <CheckCircleIcon className={isValidUpdateName() ? classes.confirmIcon : ''} />
                      </IconButton>
                      <IconButton
                        title={i18n.get('cancel')}
                        onClick={() => handleOnCancel()}
                        disabled={loading}
                      >
                        <CancelIcon className={classes.cancelIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
                  : (
                    <TableRow key={index}>
                      <TableCell>{domain.get('name')}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          title={i18n.get('edit')}
                          onClick={() => handleEditDomain(domain.get('id'), domain.get('name'))}
                          disabled={loading || editMode || deleteMode}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          title={i18n.get('delete')}
                          onClick={() => handleDeleteDomain(domain.get('id'), domain.get('name'))}
                          disabled={loading || editMode || deleteMode}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {
          (total >= 5) ? (
            <Pagination
              className={classes.pagination}
              showFirstButton
              showLastButton
              count={Math.ceil(total / 5)}
              page={page}
              onChange={(_event, value) => handlePageChange(value)}
              color="primary"
              disabled={loading || editMode || deleteMode}
            />
          ) : null
        }
      </AccordionDetails>
    </Accordion>
  )
}

AllowedDomains.propTypes = {
  domains: PropTypes.instanceOf(List).isRequired,
  defaultDomains: PropTypes.instanceOf(List).isRequired,
  formData: PropTypes.instanceOf(Map).isRequired,
  query: PropTypes.string.isRequired,
  newDomain: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  deleteMode: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,

  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onNewDomainChange: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  toggleDeleteMode: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired
}
