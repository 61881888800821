import React from 'react'
import { PropTypes } from 'prop-types'
import useI18n from 'hooks/useI18n'

import ThemedAppBar from 'containers/AppBar'

import Actions from 'containers/email_templates_library/Actions'
import TransitionComponent from 'components/transition_component'

export default function AppBar({ isDomainVerified }) {
  const i18n = useI18n()

  return (
    <TransitionComponent type="slideFadeDown">
      <ThemedAppBar
        title={<span>{i18n.get('template_library')}<sup>beta</sup></span>}
        actions={isDomainVerified ? <Actions /> : null}
      />
    </TransitionComponent>
  )
}

AppBar.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired
}
