import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import camelcase from 'camelcase'

import { deleteAllCookies, readCookie } from 'utils/cookieHelper'
import { isValidFilename } from 'utils/filename'
import themes from 'static/themes'
import { CookieNames } from 'static/constants'

import * as Actions from 'actions/config'
import * as DarknetActions from 'actions/darknet'
import * as SavedDashboardActions from 'actions/saved_dashboard'
import * as SavedChartsActions from 'actions/saved_charts'
import * as AppActions from 'actions/app'
import * as UserActions from 'actions/user'
import * as ApiKeysActions from 'actions/api_keys'

export const initialState = fromJS({
  updateConfigRequestStarted: false,
  verifyContentDeskDomainRequestStarted: false,
  dateFilterSettingId: 1,
  defaultDateType: 'article',
  defaultNewsPageView: 'card_view',
  grootGroupingSettingId: 1,
  defaultSortingId: 1,
  exportFilename: 'media_review_%year%_%month%_%day%',
  pdfCoverTitle: '',
  pdfCovers: [],
  hasTonality: false,
  separateCoding: false,
  isValidFilename: true,
  isSuper: false,
  currency: 'EUR',
  currencyRate: 1.0,
  senderMail: 'customerservice@pressrelations.de',
  exportLimit: 0,
  evaluationSteps: 0,
  statementEvaluationSteps: 0,
  statementBasedCoding: false,
  xlsxExportLimit: 0,
  backwardsNewsHtmlExportLimit: 0,
  backwardsNewsXlsxExportLimit: 0,
  savedSearchFeedLimit: 0,
  googleAnalyticsClientId: null,
  googleAnalyticsEnabled: false,
  youtubeAnalyticsEnabled: false,
  facebookAnalyticsClientId: null,
  facebookAnalyticsEnabled: false,
  facebookAccountConnected: false,
  linkedInClientId: null,
  linkedInAnalyticsEnabled: false,
  linkedInPermissions: null,
  deeplApiKey: null,
  apiKeyRequestRunning: false,
  orderRequestRunning: false,
  initialRoute: readCookie(CookieNames.INITIAL_ROUTE) || null,
  firstConfigLoaded: false,
  startedWithDeepLink: true,
  startedWithDeepLinkSet: false,
  userConfig: {
    newsSavedSearchId: null,
    profileMonitoringSavedSearchId: null,
    newsPoolSavedSearchId: null,
    dashboardId: null
  },
  colors: themes[0].colors.light,
  themes,
  selectedTheme: null,
  newsPoolConfig: {
    savedSearchCount: 0,
    timeLimit: 0,
    timeUnit: 'months',
    channels: []
  },
  newsConfig: {
    allowedChannelIds: [],
    allowedCountryIds: [],
    allowedCodeIds: []
  },
  dashboardConfig: {
    savedDashboardsCount: 0,
    savedChartsCount: 0
  },
  showFlags: true,
  expandTopics: true,
  showSummaries: false,
  showSnippets: false,
  darkowlSearchQueries: [],
  darkowlScoreQueries: [],
  contentDeskConfig: {
    senderEmail: '',
    domain: '',
    domainRegion: '',
    isDomainVerified: false,
    useCustomDomain: false,
    ccdPlan: {},
    ccdPlanUsage: {},
    ccdAllowedDomains: []
  },
  showSurvey: false,
  paidFeatures: {}
})

export default handleActions({
  [Actions.setConfig]: (state, { payload }) => {
    const newConfig = { ...payload }

    newConfig.pdfCoverTitle = newConfig.pdfCoverTitle || ''
    newConfig.firstConfigLoaded = true

    return state.merge(fromJS(newConfig))
  },
  [Actions.updateConfig]: (state, { payload: config }) => state.merge(config),
  [Actions.updatePaidFeatures]: (state, { payload }) => (
    state.setIn(['paidFeatures', camelcase(payload.key)], payload.status)
  ),
  [combineActions(
    Actions.updateConfigRequestStart,
    Actions.createCDeskConfigRequestStart,
    Actions.updateCDeskConfigRequestStart,
    Actions.fetchCDeskConfigRequestStart
  )]: state => state.set('updateConfigRequestStarted', true),
  [combineActions(
    Actions.updateConfigRequestSuccess,
    Actions.createCDeskConfigRequestSuccess,
    Actions.updateCDeskConfigRequestSuccess,
    Actions.fetchCDeskConfigRequestSuccess
  )]: state => state.set('updateConfigRequestStarted', false),
  [combineActions(
    Actions.updateConfigRequestError,
    Actions.createCDeskConfigRequestError,
    Actions.updateCDeskConfigRequestError,
    Actions.fetchCDeskConfigRequestError
  )]: state => {
    deleteAllCookies()

    return state.set('updateConfigRequestStarted', false)
  },
  [Actions.verifyCDeskDomainRequestStart]: state => state.set('verifyContentDeskDomainRequestStarted', true),
  [Actions.verifyCDeskDomainRequestSuccess]: state => state.set('verifyContentDeskDomainRequestStarted', false),
  [Actions.verifyCDeskDomainRequestError]: state => state.set('verifyContentDeskDomainRequestStarted', false),
  [Actions.setCDeskConfig]: (state, { payload }) => state.set('contentDeskConfig', fromJS(payload)),
  [Actions.updateCDeskConfig]: (state, { payload }) => state.mergeIn(['contentDeskConfig'], fromJS(payload)),
  [Actions.validateFilenameInConfig]: (state, action) => (
    state.set('isValidFilename', isValidFilename(action.payload))
  ),
  [Actions.resetConfigToDefault]: () => initialState,
  [Actions.resetColors]: (state, { payload: { name, mode } }) => {
    const byName = t => name === t.get('name')
    const colors = state.get('themes').find(byName).getIn(['colors', mode])

    return state.set('selectedTheme', name).mergeIn(['colors'], colors)
  },
  [Actions.setTheme]: (state, { payload: { name, mode } }) => {
    const byName = t => name === t.get('name')

    return state.set('selectedTheme', name).mergeIn(['colors'], state.get('themes').find(byName).getIn(['colors', mode]))
  },

  [Actions.setNewsSavedSearchId]: (state, { payload: id }) => (
    state.updateIn(['userConfig', 'newsSavedSearchId'], oldId => (id === oldId ? null : id))
  ),
  [Actions.setAnalysisSavedSearchId]: (state, { payload: id }) => (
    state.updateIn(['userConfig', 'analysisSavedSearchId'], oldId => (id === oldId ? null : id))
  ),
  [Actions.setProfileMonitoringSavedSearchId]: (state, { payload: id }) => (
    state.updateIn(['userConfig', 'profileMonitoringSavedSearchId'], oldId => (id === oldId ? null : id))
  ),
  [Actions.setNewsPoolSavedSearchId]: (state, { payload: id }) => (
    state.updateIn(['userConfig', 'newsPoolSavedSearchId'], oldId => (id === oldId ? null : id))
  ),
  [Actions.setDashboardId]: (state, { payload: id }) => (
    state.updateIn(['userConfig', 'dashboardId'], oldId => (id === oldId ? null : id))
  ),
  [Actions.setUserConfigField]: (state, { payload: { field, value } }) => (
    state.setIn(['userConfig', field], value)
  ),
  [Actions.updateUserConfigRequestSuccess]: (state, { payload: id }) => state.setIn(['userConfig', 'id'], parseInt(id, 10)),

  [Actions.setGoogleAnalyticsEnabled]: state => state.set('googleAnalyticsEnabled', true),
  [Actions.setGoogleAnalyticsDisabled]: state => state.set('googleAnalyticsEnabled', false),
  [Actions.setYoutubeAnalyticsEnabled]: state => state.set('youtubeAnalyticsEnabled', true),
  [Actions.setYoutubeAnalyticsDisabled]: state => state.set('youtubeAnalyticsEnabled', false),
  [Actions.setFacebookAnalyticsEnabled]: state => state.merge({
    facebookAnalyticsEnabled: true,
    facebookAccountConnected: true
  }),
  [Actions.setFacebookAnalyticsDisabled]: state => state.merge({
    facebookAnalyticsEnabled: false,
    facebookAccountConnected: false
  }),
  [Actions.setLinkedInAnalyticsEnabled]: state => state.set('linkedInAnalyticsEnabled', true),
  [Actions.setLinkedInAnalyticsDisabled]: state => state.set('linkedInAnalyticsEnabled', false),

  [Actions.setStartedWithDeepLink]: (state, { payload }) => state.merge({
    startedWithDeepLink: payload,
    startedWithDeepLinkSet: true
  }),

  [Actions.orderPaidVersionStart]: state => state.set('orderRequestRunning', true),
  [Actions.orderPaidVersionSuccess]: state => state.set('orderRequestRunning', false),
  [Actions.orderPaidVersionError]: state => state.set('orderRequestRunning', false),

  [UserActions.setDarkMode]: (state, { payload }) => {
    const name = state.get('selectedTheme')
    const mode = payload ? 'dark' : 'light'

    const byName = t => name === t.get('name')
    const selectedTheme = state.get('themes').find(byName)

    if (!selectedTheme) {
      return state
    }

    return state.mergeIn(['colors'], selectedTheme.getIn(['colors', mode]))
  },

  [combineActions(
    SavedDashboardActions.fetchSavedDashboardSuccess,
    SavedChartsActions.fetchSavedChartSuccess
  )]: (state, { payload: { colors, currency, currencyRate } }) => {
    const newState = fromJS({ colors, currency, currencyRate })

    return state.merge(newState)
  },
  [DarknetActions.fetchAllQueriesSuccess]: (state, { payload: { darkowlSearchQueries, darkowlScoreQueries } }) => (
    state.merge({
      darkowlSearchQueries: fromJS(darkowlSearchQueries),
      darkowlScoreQueries: fromJS(darkowlScoreQueries)
    })),
  [combineActions(
    ApiKeysActions.submitApiKeyStart,
    ApiKeysActions.deleteApiKeyStart
  )]: state => state.set('apiKeyRequestRunning', true),
  [combineActions(
    ApiKeysActions.submitApiKeySuccess,
    ApiKeysActions.submitApiKeyError,
    ApiKeysActions.deleteApiKeySuccess,
    ApiKeysActions.deleteApiKeyError
  )]: state => state.set('apiKeyRequestRunning', false),
  [AppActions.resetState]: state => initialState.merge({
    colors: state.get('colors'),
    firstConfigLoaded: state.get('firstConfigLoaded')
  })
}, initialState)
