import { connect } from 'react-redux'
import { getDefaultDomains } from 'utils/mailgun'

import AllowedDomains from 'components/administration/content_desk_settings/allowed_domains'

import {
  createAllowedDomainStart as onCreate,
  updateAllowedDomainStart as onUpdate,
  deleteAllowedDomainStart as onDelete,
  searchAllowedDomainsStart as onSearch,
  setAllowedDomainsQuickSearchQuery as onQueryChange,
  setNewAllowedDomain as onNewDomainChange,
  toggleAllowedDomainsEditMode as toggleEditMode,
  toggleAllowedDomainsDeleteMode as toggleDeleteMode,
  setAllowedDomainsPage as setPage,
  setAllowedDomainFormData as setFormData
} from 'actions/administration'

import {
  getAllowedDomains,
  getAllowedDomainsPage,
  getAllowedDomainsRequestRunning,
  getAllowedDomainsQuickSearchQuery,
  getAllowedDomainsNewAllowedDomain,
  getAllowedDomainsEditMode,
  getAllowedDomainsDeleteMode,
  getAllowedDomainFormData
} from 'selectors'

const mapStateToProps = state => ({
  domains: getAllowedDomains(state).get('items'),
  defaultDomains: getDefaultDomains(),
  formData: getAllowedDomainFormData(state),
  query: getAllowedDomainsQuickSearchQuery(state),
  newDomain: getAllowedDomainsNewAllowedDomain(state),
  editMode: getAllowedDomainsEditMode(state),
  deleteMode: getAllowedDomainsDeleteMode(state),
  total: getAllowedDomains(state).get('total'),
  page: getAllowedDomainsPage(state),
  loading: getAllowedDomainsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onCreate,
    onUpdate,
    onDelete,
    onSearch,
    onQueryChange,
    onNewDomainChange,
    toggleEditMode,
    toggleDeleteMode,
    setPage,
    setFormData
  }
)(AllowedDomains)
