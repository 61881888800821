/* global FormData */
import { getBffUrl as bffUrlSelector } from 'selectors'
import store from '../store'

import * as Api from '../utils/api'

const getBffUrl = () => bffUrlSelector(store.getState())

export const createCampaign = body => Api.post(
  `${getBffUrl()}/content_desk/campaigns`,
  body
)

export const updateCampaign = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/campaigns/${id}`,
  body
)

export const deleteCampaign = id => Api.destroy(
  `${getBffUrl()}/content_desk/campaigns/${id}`
)

export const fetchCampaigns = body => Api.get(
  `${getBffUrl()}/content_desk/campaigns?page=${body.page}&limit=${body.limit}`
)

export const searchCampaigns = body => Api.post(
  `${getBffUrl()}/content_desk/campaigns/search`,
  body
)

export const createContent = body => Api.post(
  `${getBffUrl()}/content_desk/contents`,
  body
)

export const getContent = id => Api.get(
  `${getBffUrl()}/content_desk/contents/${id}`
)

export const updateContent = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/contents/${id}`,
  body
)

export const deleteContent = id => Api.destroy(
  `${getBffUrl()}/content_desk/contents/${id}`
)

export const fetchContents = body => Api.post(
  `${getBffUrl()}/content_desk/contents/ids`,
  body
)

export const searchContents = body => Api.post(
  `${getBffUrl()}/content_desk/contents/search`,
  body
)

export const lockContent = id => Api.patch(
  `${getBffUrl()}/content_desk/contents/lock/${id}`
)

export const unlockContent = id => Api.patch(
  `${getBffUrl()}/content_desk/contents/unlock/${id}`
)

export const dispatchContent = (id, body) => Api.post(
  `${getBffUrl()}/content_desk/contents/${id}/dispatch`,
  body
)

export const searchLinkedInCompany = body => Api.post(
  `${getBffUrl()}/content_desk/contents/social_media_statics/linked_in/company_search`,
  body
)

export const fetchFacebookLinkMetadata = body => Api.post(
  `${getBffUrl()}/content_desk/fetch_facebook_link_metadata`, body
)

export const downloadEmailEvents = data => (
  Api.post(
    `${getBffUrl()}/content_desk/contents/email_events/excel`, data, 'blob'
  )
)

export const createEmailTemplate = body => Api.post(
  `${getBffUrl()}/content_desk/templates`,
  body
)

export const updateEmailTemplate = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/templates/${id}`,
  body
)

export const deleteEmailTemplate = id => Api.destroy(
  `${getBffUrl()}/content_desk/templates/${id}`
)

export const fetchEmailTemplates = body => Api.post(
  `${getBffUrl()}/content_desk/templates/search`, body
)

export const editorAiRequest = body => Api.post(
  `${getBffUrl()}/content_desk/ai_actions/exec`,
  body
)

export const fetchStatistics = (id, earnedMediaAccuracy) => Api.get(
  `${getBffUrl()}/content_desk/contents/${id}/fetch_statistics?earned_media_accuracy=${earnedMediaAccuracy}`
)

export const fetchContentDeskKpiStatistics = (type, dateRange, invalidateCache) => Api.get(
  `${getBffUrl()}/content_desk/kpi_statistics?type=${type}&date_range=${dateRange}&invalidate_cache=${invalidateCache}`
)

export const searchMediaFiles = body => Api.post(
  `${getBffUrl()}/content_desk/media_files/search`,
  body
)

export const deleteMediaFile = id => Api.destroy(
  `${getBffUrl()}/content_desk/media_files/delete/${id}`
)

export const uploadEditorImage = file => {
  const formData = new FormData()
  formData.append('file', file.attachments[0], file.attachments[0].name)

  return Api.form(
    `${getBffUrl()}/content_desk/contents/upload_file`,
    formData
  )
}

export const uploadMediaFile = data => {
  const formData = new FormData()
  data.forEach(({ key, value, filename }) => {
    if (filename) {
      formData.append(key, value, filename)
    } else {
      formData.append(key, value)
    }
  })

  return Api.form(
    `${getBffUrl()}/content_desk/contents/upload_file`,
    formData
  )
}

export const createOtsDraft = (id, body) => Api.post(
  `${getBffUrl()}/content_desk/contents/${id}/ots`,
  body
)

