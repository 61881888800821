import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { findValueByChart as findGoogleAnalyticsValueByChart } from 'static/google_analytics_charts'
import { findValueByChart as findMozValueByChart } from 'static/moz_charts'
import { findValueByChart as findLinkedInValueByChart } from 'static/linked_in_analytics_charts'
import { findValueByChart as findFacebookValueByChart } from 'static/facebook_analytics_charts'
import { findValueByChart as findTwitterValueByChart } from 'static/twitter_analytics_charts'
import { StepTypes, DateModes } from 'static/constants'
import { rangeToDates, calculateIntervals } from 'utils/date'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

const { allowedDateIntervals: defaultAllowedDateIntervals } = calculateIntervals(null, new Date(), new Date())

const filterIntervalsForGoogleAnalytics = intervals => intervals.filter(i => i !== 'week')

export const initialState = fromJS({
  aggregations: {},
  index: null,
  stepType: StepTypes.CHARTS,
  dateMode: DateModes.DATE_RANGE,
  allowedDateIntervals: defaultAllowedDateIntervals,
  allowedGoogleAnalyticsIntervals: filterIntervalsForGoogleAnalytics(defaultAllowedDateIntervals),
  selectedGoogleAnalyticsChart: 'ga_chart_timeline',
  selectedMozChart: 'moz_page_authority',
  selectedGoogleAnalyticsViewId: null,
  selectedGoogleAnalyticsSegmentId: null,
  selectedLinkedInAnalyticsChart: 'li_chart_timeline',
  selectedFacebookAnalyticsChart: 'page_views',
  selectedTwitterAnalyticsChart: 'social_media_analytics_feed',
  multipleSavedSearchesMode: false,
  uploadedPhoto: null
})

const updateIntervals = (state, dateFrom, dateTo, dateRange) => {
  if (dateFrom && dateTo) {
    const { allowedDateIntervals } = calculateIntervals(null, dateFrom, dateTo, dateRange)

    return state
      .set('allowedDateIntervals', allowedDateIntervals)
      .set('allowedGoogleAnalyticsIntervals', filterIntervalsForGoogleAnalytics(allowedDateIntervals))
  }

  const { from, to } = rangeToDates(dateRange)
  const { allowedDateIntervals } = calculateIntervals(null, from, to, dateRange)

  return state
    .set('allowedDateIntervals', allowedDateIntervals)
    .set('allowedGoogleAnalyticsIntervals', filterIntervalsForGoogleAnalytics(allowedDateIntervals))
}

export default handleActions({
  [Actions.setSelectedChart]: (state, { payload: chart }) => updateIntervals(
    state,
    chart.get('dateFrom'),
    chart.get('dateTo'),
    chart.get('dateRange')
  ),

  [Actions.selectDateRange]: (state, { payload: dateRange }) => updateIntervals(state, null, null, dateRange),

  [Actions.selectDateMode]: (state, { payload: dateMode }) => state.set('dateMode', dateMode),

  [Actions.selectDate]: (state, { payload: { dateFrom, dateTo } }) => {
    if (dateFrom && dateTo) {
      return updateIntervals(state, dateFrom, dateTo, null)
    }

    return state
  },

  [Actions.showChartDialog]: state => state.set('uploadedPhoto', null),

  [Actions.setSelectedChartStepType]: (state, { payload: stepType }) => state.set('stepType', stepType),

  [Actions.fetchSavedSearchAggregationsStart]: (state, { payload }) => {
    const newState = state

    if (payload) {
      const { chart, index, isMulti } = payload

      let dateMode = DateModes.DATE_RANGE

      if (chart.get('dateFrom') && chart.get('dateTo')) {
        dateMode = DateModes.DATE
      } else if ((chart.get('dateRange') || '').startsWith('relative_range')) {
        dateMode = DateModes.RELATIVE
      }

      return newState.merge(fromJS({
        index,
        aggregations: {},
        multipleSavedSearchesMode: (
          Boolean(isMulti) || chart.get('firstLevel') === 'savedSearches' || chart.get('secondLevel') === 'savedSearches'
        ),
        dateMode,
        selectedGoogleAnalyticsChart: findGoogleAnalyticsValueByChart(chart) || state.get('selectedGoogleAnalyticsChart'),
        selectedMozChart: findMozValueByChart(chart) || state.get('selectedMozChart'),
        selectedLinkedInAnalyticsChart: findLinkedInValueByChart(chart) || state.get('selectedLinkedInAnalyticsChart'),
        selectedFacebookAnalyticsChart: findFacebookValueByChart(chart) || state.get('selectedFacebookAnalyticsChart'),
        selectedTwitterAnalyticsChart: findTwitterValueByChart(chart) || state.get('selectedTwitterAnalyticsChart')
      }))
    }

    return newState.merge(fromJS({
      index: null,
      aggregations: {}
    }))
  },

  [Actions.fetchSavedSearchAggregationsSuccess]: (state, { payload: aggs }) => (
    state.set('aggregations', fromJS(aggs))
  ),

  [Actions.selectGoogleAnalyticsChart]: (state, { payload: chart }) => state.set('selectedGoogleAnalyticsChart', chart),
  [Actions.selectMozChart]: (state, { payload: chart }) => state.set('selectedMozChart', chart),
  [Actions.selectLinkedInAnalyticsChart]: (state, { payload: chart }) => state.set('selectedLinkedInAnalyticsChart', chart),
  [
  combineActions(
    Actions.selectFacebookAnalyticsChart,
    Actions.setFacebookAnalyticsChart
  )
  ]: (state, { payload: chart }) => state.set('selectedFacebookAnalyticsChart', chart),
  [Actions.selectTwitterAnalyticsChart]: (state, { payload: chart }) => state.set('selectedTwitterAnalyticsChart', chart),

  [Actions.setSelectedChartUploadedPhoto]: (state, { payload: data }) => state.set('uploadedPhoto', data),

  [Actions.setSelectedChartMultipleSavedSearchesMode]: (state, { payload }) => state.set('multipleSavedSearchesMode', payload),

  [AppActions.resetState]: () => initialState
}, initialState)
