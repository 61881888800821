import { connect } from 'react-redux'
import { getTopLevelDomain } from 'utils/regex'
import { getDefaultDomains, getDefaultDomainByTld } from 'utils/mailgun'

import ContentDeskSettings from 'components/administration/content_desk_settings'

import {
  createCDeskConfigRequestStart as onSave,
  verifyCDeskDomainRequestStart as onVerify,
  updateCDeskConfigRequestStart as onUpdate,
  updateCDeskConfig as onChange,
  refreshContentDeskPlanUsageAndLimits as onConfigRefresh
} from 'actions/config'

import {
  getCDeskConfig,
  getCDeskUpdateRequestRunning,
  getCDeskVerifyRequestRunning,
  getCapabilities,
  getUser,
  getCDeskUploadedMediaFilesTotalSize
} from 'selectors'

const getDefaultDomain = user => {
  const tld = getTopLevelDomain(user.get('email'))

  return getDefaultDomainByTld(tld)
}

const mapStateToProps = state => ({
  capabilities: getCapabilities(state),
  config: getCDeskConfig(state),
  defaultDomains: getDefaultDomains(),
  defaultDomainByTld: getDefaultDomain(getUser(state)),
  requestRunning: getCDeskUpdateRequestRunning(state) || getCDeskVerifyRequestRunning(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state)
})

export default connect(
  mapStateToProps,
  {
    onSave,
    onChange,
    onUpdate,
    onVerify,
    onConfigRefresh
  }
)(ContentDeskSettings)
