import { call, put, takeEvery, all, select } from 'redux-saga/effects'

import * as Actions from 'actions/ust'
import * as UST from 'utils/ust'
import {
  getUstAllowedIps
} from 'selectors'

export function* initialise({ payload }) {
  const allowedIps = yield select(getUstAllowedIps)

  if (allowedIps.some(ip => payload.startsWith(ip))) {
    yield call(UST.enableRecording)
    yield put(Actions.ustEnableRecording(payload))
  }
}

export function* event({ payload: eventData }) {
  const created = yield call(UST.getMoment)

  try {
    yield call(UST.event, eventData)
    yield put(Actions.ustEventSuccess({ event: eventData, created }))
  } catch (error) {
    yield put(Actions.ustEventError({ event: eventData, error }))
  }
}

export function* tag({ payload: tagData }) {
  const created = yield call(UST.getMoment)

  try {
    yield call(UST.tag, tagData)
    yield put(Actions.ustTagSuccess({ tag: tagData, created }))
  } catch (error) {
    yield put(Actions.ustTagError({ tag: tagData, error }))
  }
}

export function* pageEnter({ payload: page }) {
  const eventData = {
    category: 'VISIT', // REQUIRED
    action: 'PAGE_ENTER', // REQUIRED
    label: 'page',
    value: page
  }

  yield put(Actions.ustEventStarted(eventData))
}

export function* pageLeave({ payload: page }) {
  const eventData = {
    category: 'VISIT', // REQUIRED
    action: 'PAGE_LEAVE', // REQUIRED
    label: 'page',
    value: page
  }

  yield put(Actions.ustEventStarted(eventData))
}

export function* watchUstEventStarted() {
  yield takeEvery(Actions.ustEventStarted, event)
}

export function* watchUstTagStarted() {
  yield takeEvery(Actions.ustTagStarted, tag)
}

export function* watchUstPageEnter() {
  yield takeEvery(Actions.ustPageEnter, pageEnter)
}

export function* watchUstPageLeave() {
  yield takeEvery(Actions.ustPageLeave, pageLeave)
}

export function* watchUstInitialise() {
  yield takeEvery(Actions.ustInitialise, initialise)
}

export default function* ustSaga() {
  yield all([
    watchUstEventStarted(),
    watchUstTagStarted(),
    watchUstPageEnter(),
    watchUstPageLeave(),
    watchUstInitialise()
  ])
}
