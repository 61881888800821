import { connect } from 'react-redux'

import { getDefaultDomains } from 'utils/mailgun'
import Settings from 'components/content_desk_new/contents/content_edit_dialog/settings'

import {
  updateContent,
  fetchDistributionListDropdownOptionsStart
} from 'actions/content_desk'

import {
  getCDeskConfig,
  getCDeskContentFormData,
  getCDeskEmailEditorLoadingStatus
} from 'selectors'

const mapStateToProps = state => ({
  config: getCDeskConfig(state),
  contentFormData: getCDeskContentFormData(state),
  defaultDomains: getDefaultDomains(),
  emailEditorLoading: getCDeskEmailEditorLoadingStatus(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: updateContent,
    fetchDistributionLists: () => fetchDistributionListDropdownOptionsStart({ searchString: '' })
  }
)(Settings)
