import sanitizeHtml from 'sanitize-html'

const emailVariables = [
  '%recipient.full_name%',
  '%recipient.first_name%',
  '%recipient.last_name%',
  '%tag_unsubscribe_url%'
]

export const getMediaFileSrcAndFit = mf => {
  let src
  let style = { objectFit: 'fill' }

  switch (mf.get('mimeType')) {
    case 'application/msword':
      src = 'https://static.pressrelations.de/pics/groot/ccd/doc-icon.svg'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      src = 'https://static.pressrelations.de/pics/groot/ccd/docx-icon.svg'
      break
    case 'application/vnd.ms-excel':
      src = 'https://static.pressrelations.de/pics/groot/ccd/xls-icon.svg'
      break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      src = 'https://static.pressrelations.de/pics/groot/ccd/xlsx-icon.svg'
      break
    case 'text/csv':
      src = 'https://static.pressrelations.de/pics/groot/ccd/csv-icon.svg'
      break
    case mf.get('mimeType').match(/^image/)?.input:
      src = mf.get('url')
      style = { objectFit: 'contain' }
      break
    default:
      src = 'https://static.pressrelations.de/pics/groot/ccd/unknown-format-icon.svg'
      break
  }

  return { src, style }
}

export const isEditorDesignBlank = editorDesign => {
  if (!editorDesign) {
    return true
  }

  const rows = editorDesign.getIn(['body', 'rows'])

  let isEmpty = true

  rows.forEach(row => {
    const columns = row.get('columns')
    columns.forEach(column => {
      const contents = column.get('contents')

      if (!contents.isEmpty()) {
        isEmpty = false
      }
    })
  })

  return isEmpty
}

export const emailExtractImageLinks = html => {
  const regex = /https:\/\/public-api\.pressrelations\.de\/files-api\/stored_files\/[a-zA-Z0-9-]+/g

  return html?.match(regex) || []
}

export const emailToOtsPlainText = html => {
  if (!html) {
    return ''
  }

  const allowedTags = ['p', 'ol', 'ul', 'li', 'b', 'strong', 'i', 'em', 'u', 'a', 'br']

  let sanitizedHtml = sanitizeHtml(
    html
      .replace(/<\/?span[^>]*>/g, '')
      .replace(/<h[1-9][^>]*>/g, '<p>')
      .replace(/<\/h[1-9]>/g, '</p>')
      .replace(/[\r\n]+/g, '')
      .replace(/<(\w+)(\s*[^>]*)>\s*<\/\1>/g, ''), // remove empty tags
    {
      allowedTags,
      allowedClasses: {},
      allowedAttributes: {
        a: ['href']
      },
      disallowedTagsMode: 'completelyDiscard'
    }
  )

  emailVariables.forEach(variable => {
    sanitizedHtml = sanitizedHtml.replace(variable, '')
  })

  return sanitizedHtml
}

export const removeHtmlTags = html => {
  if (!html) {
    return ''
  }

  return sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {}
  })
}
