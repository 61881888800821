import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { orange } from '@mui/material/colors'
import { lighten, darken } from '@mui/material/styles'
import { Box, TextField, Typography } from '@mui/material'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { RichTextEditor } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte'
import OtsGuidelinesDialog from 'components/content_desk_new/ots_guidelines_dialog/OtsGuidelinesDialog'

import { Button } from 'containers/themed'
import FilesGallery from 'containers/content_desk_new/contents/content_edit_dialog/attachments/FilesGallery'
import ImagePreviewDialog from 'containers/content_desk_new/contents/content_edit_dialog/attachments/ImagePreviewDialog'

import { emailExtractImageLinks, removeHtmlTags } from 'utils/content_desk'
import { removeEmptyRootParagraph, removeInvalidTagsForOts } from 'utils/lexicalEditorHelper'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '40px 20px 0px 20px'
  },
  editorContainerStyle: {
    height: '100%',
    margin: '10px 0 !important'
  },
  textGuidelineContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textEditorInnerStyle: {
    height: '350px',
    '* p': {
      marginBottom: '0.8rem'
    },
    '* p:last-child': {
      marginBottom: 0
    },
    '* ul li': {
      listStyleType: 'disc'
    },
    '* ol li': {
      listStyleType: 'inherit'
    },
    '* td li': {
      marginLeft: '0px !important'
    },
    '* pre::-webkit-scrollbar': {
      background: 'transparent',
      width: '10px'
    },
    '* pre::-webkit-scrollbar-thumb': {
      background: '#999'
    }
  },
  contactEditorInnerStyle: {
    height: '150px'
  },
  sectionLabel: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  imageInfoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '10px'
  },
  warningContainer: {
    display: 'flex',
    width: '100%',
    gap: '15px',
    backgroundColor: lighten(orange[50], 0.5),
    border: '1px solid',
    borderRadius: '5px',
    borderColor: orange[800],
    padding: '20px'
  },
  titleLabel: {
    fontSize: '18px',
    fontWeight: '500',
    color: darken(orange[800], 0.5),
    marginBottom: '5px'
  },
  infoLabel: {
    fontSize: '14px',
    color: theme.palette.text.secondary
  },
  warningIcon: {
    color: orange[800]
  }
}))

const OtsEditor = ({
  contentFormData,
  onChange
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [guidelinesDialogOpen, setGuidelinesDialogOpen] = useState(false)

  const handleGuidelinedialogClose = () => {
    setGuidelinesDialogOpen(false)
  }

  const textFirstRender = useRef(true)
  const contactFirstRender = useRef(true)

  const title = contentFormData.get('title')
  const otsContent = contentFormData.get('otsContent')
  let html = ''

  if (otsContent) {
    html = otsContent.get('body')
  }

  const images = emailExtractImageLinks(html)

  const [showAllImages, setShowAllImages] = useState(images.length === 0)

  const body = JSON.parse(contentFormData.get('body'))

  const { location } = body
  const { text } = body
  const { contact } = body

  const storyTextAllowedTags = ['P', 'I', 'B', 'U', 'A', 'UL', 'OL', 'LI']
  const contactTextAllowedTags = ['P', 'I', 'B', 'U', 'A', 'BR']

  const handleChange = (field, value) => {
    let updatedText = removeEmptyRootParagraph(value)

    let allowedTags = []

    if (field === 'text') {
      allowedTags = storyTextAllowedTags
    } else if (field === 'contact') {
      allowedTags = contactTextAllowedTags
    }

    updatedText = removeInvalidTagsForOts(updatedText, allowedTags)
    const newBody = { ...body, [field]: updatedText }

    // Skip updating the body on the first render because of isDirty
    if (textFirstRender.current && field === 'text') {
      textFirstRender.current = false

      return
    }

    if (contactFirstRender.current && field === 'contact') {
      contactFirstRender.current = false

      return
    }

    const plainText = `${newBody.location}\n${removeHtmlTags(newBody.text)}\n${removeHtmlTags(newBody.contact)}`

    onChange({ body: JSON.stringify(newBody) })
    onChange({ plainText })
  }

  return (
    <Box className={classes.container}>
      <TextField
        fullWidth
        required
        label={i18n.get('title')}
        value={title}
        onChange={e => onChange({ title: e.target.value })}
      />
      <br /><br />
      <TextField
        fullWidth
        required
        value={location}
        onChange={e => handleChange('location', e.target.value)}
        label={i18n.get('location')}
      />
      <br /><br />
      <Box className={classes.textGuidelineContainer}>
        <Typography className={classes.sectionLabel}>
          {i18n.get('text')}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setGuidelinesDialogOpen(true)}
        >
          {i18n.get('press_release_guidelines')}
        </Button>
      </Box>
      <RichTextEditor
        storyTextField
        editorState={removeInvalidTagsForOts(text, storyTextAllowedTags) || '<p></p>'}
        setEditorState={value => handleChange('text', value)}
        editorContainerStyle={classes.editorContainerStyle}
        editorInnerStyle={classes.textEditorInnerStyle}
      />
      <br />
      <Typography className={classes.sectionLabel}>
        {i18n.get('contact')}
      </Typography>
      <RichTextEditor
        contactTextField
        editorState={removeInvalidTagsForOts(contact, contactTextAllowedTags) || '<p></p>'}
        setEditorState={value => handleChange('contact', value)}
        editorContainerStyle={classes.editorContainerStyle}
        editorInnerStyle={classes.contactEditorInnerStyle}
        disableTextFormat
      />
      <br />
      <Box className={classes.imageInfoContainer}>
        <Box>
          <Typography className={classes.sectionLabel}>
            {i18n.get('choose_your_image')}
          </Typography>
        </Box>
        {!showAllImages && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAllImages(true)}
          >
            {i18n.get('show_all')}
          </Button>
        )}
      </Box>
      <br />
      <Box className={classes.warningContainer}>
        <WarningAmberIcon className={classes.warningIcon} />
        <Box>
          <Typography className={classes.titleLabel}>
            {i18n.get('ots_only_jpeg_supported')}
          </Typography>
          <Typography
            className={classes.infoLabel}
            dangerouslySetInnerHTML={{
              __html: i18n.get('ots_choose_your_image_info')
            }}
          />
        </Box>
      </Box>
      {!showAllImages ? (
        <FilesGallery
          srcFilter={images}
          totalAttachmentsLimit={1}
          totalAttachmentsSizeLimit={10485760}
          mimeTypeFilter={['image/jpeg']}
          uploadDisabled
          deleteDisabled
          forceLoadAll
        />
      ) : (
        <FilesGallery
          srcSort={images}
          totalAttachmentsLimit={1}
          totalAttachmentsSizeLimit={10485760}
          mimeTypeFilter={['image/jpeg']}
          uploadDisabled
          deleteDisabled
        />
      )}
      <br />
      <ImagePreviewDialog />
      <OtsGuidelinesDialog
        open={guidelinesDialogOpen}
        onClose={handleGuidelinedialogClose}
      />
    </Box>
  )
}

OtsEditor.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,

  onChange: PropTypes.func.isRequired
}

export default OtsEditor
