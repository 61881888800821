/* eslint-disable import/named */
import { createAction } from 'redux-actions'

const defaultPrefix = 'CD/'
let prefix = defaultPrefix

// DATA
export const setContents = createAction(`${prefix}SET_CONTENTS`)
export const setContentFormData = createAction(`${prefix}SET_CONTENT_FORM_DATA`)
export const updateContent = createAction(`${prefix}UPDATE_CONTENT_FORM_DATA`)
export const cloneContentsFormData = createAction(`${prefix}CLONE_CONTENTS_FORM_DATA`)
export const updateCampaignFormData = createAction(`${prefix}UPDATE_CAMPAIGN_FORM_DATA`)
export const openCampaignForm = createAction(`${prefix}OPEN_NEW_CAMPAIGN_FORM`)
export const resetCampaignForm = createAction(`${prefix}RESET_CAMPAIGN_FORM`)
export const resetCampaigns = createAction(`${prefix}RESET_CAMPAIGNS`)
export const resetContents = createAction(`${prefix}RESET_CONTENTS`)

export const getContentStart = createAction(`${prefix}GET_CONTENT_START`)
export const getContentSuccess = createAction(`${prefix}GET_CONTENT_SUCCESS`)
export const getContentError = createAction(`${prefix}GET_CONTENT_ERROR`)

export const lockContentStart = createAction(`${prefix}LOCK_CONTENT_START`)
export const lockContentSuccess = createAction(`${prefix}LOCK_CONTENT_SUCCESS`)
export const lockContentError = createAction(`${prefix}LOCK_CONTENT_ERROR`)

export const unlockContentStart = createAction(`${prefix}UNLOCK_CONTENT_START`)
export const unlockContentSuccess = createAction(`${prefix}UNLOCK_CONTENT_SUCCESS`)
export const unlockContentError = createAction(`${prefix}UNLOCK_CONTENT_ERROR`)

export const search = createAction(`${prefix}SEARCH`)

export const searchCampaignsStart = createAction(`${prefix}SEARCH_CAMPAIGNS_START`)
export const searchCampaignsSuccess = createAction(`${prefix}SEARCH_CAMPAIGNS_SUCCESS`)
export const searchCampaignsError = createAction(`${prefix}SEARCH_CAMPAIGNS_ERROR`)

export const searchContentsStart = createAction(`${prefix}SEARCH_CONTENTS_START`)
export const searchContentsSuccess = createAction(`${prefix}SEARCH_CONTENTS_SUCCESS`)
export const searchContentsError = createAction(`${prefix}SEARCH_CONTENTS_ERROR`)

export const fetchCampaignDropdownOptionsStart = createAction(`${prefix}FETCH_CAMPAIGN_DROPDOWN_OPTIONS_START`)
export const fetchCampaignDropdownOptionsSuccess = createAction(`${prefix}FETCH_CAMPAIGN_DROPDOWN_OPTIONS_SUCCESS`)
export const fetchCampaignDropdownOptionsError = createAction(`${prefix}FETCH_CAMPAIGN_DROPDOWN_OPTIONS_ERROR`)

export const fetchContentDropdownOptionsStart = createAction(`${prefix}FETCH_CONTENT_DROPDOWN_OPTIONS_START`)
export const fetchContentDropdownOptionsSuccess = createAction(`${prefix}FETCH_CONTENT_DROPDOWN_OPTIONS_SUCCESS`)
export const fetchContentDropdownOptionsError = createAction(`${prefix}FETCH_CONTENT_DROPDOWN_OPTIONS_ERROR`)

export const fetchDistributionListDropdownOptionsStart = createAction(`${prefix}FETCH_DISTRIBUTION_LIST_DROPDOWN_OPTIONS_START`)
export const fetchDistributionListDropdownOptionsSuccess = createAction(`${prefix}FETCH_DISTRIBUTION_LIST_DROPDOWN_OPTIONS_SUCCESS`)
export const fetchDistributionListDropdownOptionsError = createAction(`${prefix}FETCH_DISTRIBUTION_LIST_DROPDOWN_OPTIONS_ERROR`)

export const fetchContactDropdownOptionsStart = createAction(`${prefix}FETCH_CONTACT_DROPDOWN_OPTIONS_START`)
export const fetchContactDropdownOptionsSuccess = createAction(`${prefix}FETCH_CONTACT_DROPDOWN_OPTIONS_SUCCESS`)
export const fetchContactDropdownOptionsError = createAction(`${prefix}FETCH_CONTACT_DROPDOWN_OPTIONS_ERROR`)

export const fetchStatisticsStart = createAction(`${prefix}FETCH_STATISTICS_START`)
export const fetchStatisticsSuccess = createAction(`${prefix}FETCH_STATISTICS_SUCCESS`)
export const fetchStatisticsError = createAction(`${prefix}FETCH_STATISTICS_ERROR`)

export const resetEvents = createAction(`${prefix}RESET_EVENTS`)

export const saveContentStart = createAction(`${prefix}SAVE_CONTENT_START`)
export const saveContentSuccess = createAction(`${prefix}SAVE_CONTENT_SUCCESS`)
export const saveContentError = createAction(`${prefix}SAVE_CONTENT_ERROR`)

export const saveContentFormlessStart = createAction(`${prefix}SAVE_CONTENT_FORMLESS_START`)
export const saveContentFormlessSuccess = createAction(`${prefix}SAVE_CONTENT_FORMLESS_SUCCESS`)
export const saveContentFormlessError = createAction(`${prefix}SAVE_CONTENT_FORMLESS_ERROR`)

export const saveDeletedOnSocialForContentStart = createAction(`${prefix}SAVE_DELETED_ON_SOCIAL_CONTENT_START`)
export const saveDeletedOnSocialForContentSuccess = createAction(`${prefix}SAVE_DELETED_ON_SOCIAL_CONTENT_SUCCESS`)
export const saveDeletedOnSocialForContentError = createAction(`${prefix}SAVE_DELETED_ON_SOCIAL_CONTENT_ERROR`)

export const dispatchContentRequestStart = createAction(`${prefix}DISPATCH_CONTENT_REQUEST_START`)
export const dispatchContentRequestSuccess = createAction(`${prefix}DISPATCH_CONTENT_REQUEST_SUCCESS`)
export const dispatchContentRequestError = createAction(`${prefix}DISPATCH_CONTENT_REQUEST_ERROR`)

export const uploadLinkedInMediaFileStart = createAction(`${prefix}UPLOAD_LINKED_IN_MEDIA_FILE_START`)
export const uploadLinkedInMediaFileSuccess = createAction(`${prefix}UPLOAD_LINKED_IN_MEDIA_FILE_SUCCESS`)
export const uploadLinkedInMediaFileError = createAction(`${prefix}UPLOAD_LINKED_IN_MEDIA_FILE_ERROR`)

export const uploadFacebookMediaFileStart = createAction(`${prefix}UPLOAD_FACEBOOK_MEDIA_FILE_START`)
export const uploadFacebookMediaFileSuccess = createAction(`${prefix}UPLOAD_FACEBOOK_MEDIA_FILE_SUCCESS`)
export const uploadFacebookMediaFileError = createAction(`${prefix}UPLOAD_FACEBOOK_MEDIA_FILE_ERROR`)

export const fetchMediaFilesStart = createAction(`${prefix}FETCH_MEDIA_FILES_START`)
export const fetchMediaFilesSuccess = createAction(`${prefix}FETCH_MEDIA_FILES_SUCCESS`)
export const fetchMediaFilesError = createAction(`${prefix}FETCH_MEDIA_FILES_ERROR`)

export const fetchMoreMediaFilesStart = createAction(`${prefix}FETCH_MORE_MEDIA_FILES_START`)
export const fetchMoreMediaFilesSuccess = createAction(`${prefix}FETCH_MORE_MEDIA_FILES_SUCCESS`)
export const fetchMoreMediaFilesError = createAction(`${prefix}FETCH_MORE_MEDIA_FILES_ERROR`)

export const deleteMediaFileStart = createAction(`${prefix}DELETE_MEDIA_FILE_START`)
export const deleteMediaFileSuccess = createAction(`${prefix}DELETE_MEDIA_FILE_SUCCESS`)
export const deleteMediaFileError = createAction(`${prefix}DELETE_MEDIA_FILE_ERROR`)

export const appendMediaFiles = createAction(`${prefix}APPEND_MEDIA_FILES`)

export const refreshContentsStatusStart = createAction(`${prefix}REFRESH_CONTENTS_STATUS_START`)
export const refreshContentsStatusSuccess = createAction(`${prefix}REFRESH_CONTENTS_STATUS_SUCCESS`)
export const refreshContentsStatusError = createAction(`${prefix}REFRESH_CONTENTS_STATUS_ERROR`)
export const stopRefreshContentsStatus = createAction(`${prefix}STOP_REFRESH_CONTENTS_STATUS`)

export const editorAiRequestStart = createAction(`${prefix}EDITOR_AI_REQUEST_START`)
export const editorAiRequestSuccess = createAction(`${prefix}EDITOR_AI_REQUEST_SUCCESS`)
export const editorAiRequestError = createAction(`${prefix}EDITOR_AI_REQUEST_ERROR`)

export const resetSocialMediaPostsAndUploads = createAction(`${prefix}RESET_SOCIAL_MEDIA_POSTS_AND_UPLOADS`)
export const setUploadedMediaFilesTotalSize = createAction(`${prefix}SET_UPLOADED_MEDIA_FILES_TOTAL_SIZE`)

export const buildSocialMediaLinkStart = createAction(`${prefix}BUILD_SOCIAL_MEDIA_LINK_START`)
export const buildSocialMediaLinkLinkedInSuccess = createAction(`${prefix}BUILD_SOCIAL_MEDIA_LINK_LINKEDIN_SUCCESS`)
export const buildSocialMediaLinkFacebookSuccess = createAction(`${prefix}BUILD_SOCIAL_MEDIA_LINK_FACEBOOK_SUCCESS`)
export const buildSocialMediaLinkLinkedInError = createAction(`${prefix}BUILD_SOCIAL_MEDIA_LINK_LINKEDIN_ERROR`)
export const buildSocialMediaLinkFacebookError = createAction(`${prefix}BUILD_SOCIAL_MEDIA_LINK_FACEBOOK_ERROR`)

export const downloadEmailEventsStart = createAction(`${prefix}DOWNLOAD_EMAIL_EVENTS_START`)
export const downloadEmailEventsSuccess = createAction(`${prefix}DOWNLOAD_EMAIL_EVENTS_SUCCESS`)
export const downloadEmailEventsError = createAction(`${prefix}DOWNLOAD_EMAIL_EVENTS_ERROR`)

export const saveCampaignFormlessStart = createAction(`${prefix}SAVE_CAMPAIGN_FORMLESS_START`)
export const saveCampaignFormlessSuccess = createAction(`${prefix}SAVE_CAMPAIGN_FORMLESS_SUCCESS`)
export const saveCampaignFormlessError = createAction(`${prefix}SAVE_CAMPAIGN_FORMLESS_ERROR`)

export const fetchKpiStatisticsStart = createAction(`${prefix}FETCH_KPI_STATISTICS_START`)
export const fetchKpiStatisticsSuccess = createAction(`${prefix}FETCH_KPI_STATISTICS_SUCCESS`)
export const fetchKpiStatisticsError = createAction(`${prefix}FETCH_KPI_STATISTICS_ERROR`)
export const removeRecipient = createAction(`${prefix}REMOVE_RECIPIENT`)

export const fetchRecipientContactStart = createAction(`${prefix}FETCH_RECIPIENT_CONTACT_START`)
export const fetchRecipientContactSuccess = createAction(`${prefix}FETCH_RECIPIENT_CONTACT_SUCCESS`)
export const fetchRecipientContactError = createAction(`${prefix}FETCH_RECIPIENT_CONTACT_ERROR`)

export const setDistributionListSearchQuery = createAction(`${prefix}SET_DISTRIBUTION_LIST_SEARCH_QUERY`)

export const createOtsDraftStart = createAction(`${prefix}CREATE_OTS_DRAFT_START`)
export const createOtsDraftSuccess = createAction(`${prefix}CREATE_OTS_DRAFT_SUCCESS`)
export const createOtsDraftError = createAction(`${prefix}CREATE_OTS_DRAFT_ERROR`)

// UI
prefix = `${defaultPrefix}UI/`
export const openEditContentDialog = createAction(`${prefix}OPEN_EDIT_CONTENT_DIALOG`)
export const closeEditContentDialog = createAction(`${prefix}CLOSE_EDIT_CONTENT_DIALOG`)

export const openOldEditContentDialog = createAction(`${prefix}OPEN_OLD_EDIT_CONTENT_DIALOG`)
export const closeOldEditContentDialog = createAction(`${prefix}CLOSE_OLD_EDIT_CONTENT_DIALOG`)

export const openNewContentDialog = createAction(`${prefix}OPEN_NEW_CONTENT_DIALOG`)
export const closeNewContentDialog = createAction(`${prefix}CLOSE_NEW_CONTENT_DIALOG`)

export const openViewContentDialog = createAction(`${prefix}OPEN_VIEW_CONTENT_DIALOG`)
export const closeViewContentDialog = createAction(`${prefix}CLOSE_VIEW_CONTENT_DIALOG`)

export const openDeleteDialog = createAction(`${prefix}OPEN_DELETE_DIALOG`)
export const closeDeleteDialog = createAction(`${prefix}CLOSE_DELETE_DIALOG`)

export const openScheduleDialog = createAction(`${prefix}OPEN_SCHEDULE_DIALOG`)
export const closeScheduleDialog = createAction(`${prefix}CLOSE_SCHEDULE_DIALOG`)

export const openOldScheduleDialog = createAction(`${prefix}OPEN_OLD_SCHEDULE_DIALOG`)
export const closeOldScheduleDialog = createAction(`${prefix}CLOSE_OLD_SCHEDULE_DIALOG`)

export const openPlanDialog = createAction(`${prefix}OPEN_PLAN_DIALOG`)
export const closePlanDialog = createAction(`${prefix}CLOSE_PLAN_DIALOG`)

export const openSaveTemplateDialog = createAction(`${prefix}OPEN_SAVE_TEMPLATE_DIALOG`)
export const closeSaveTemplateDialog = createAction(`${prefix}CLOSE_SAVE_TEMPLATE_DIALOG`)

export const setDeleteContentData = createAction(`${prefix}SET_DELETE_CONTENT_DATA`)
export const closeCampaignForm = createAction(`${prefix}CLOSE_CAMPAIGN_FORM`)
export const setCampaignDialogEditMode = createAction(`${prefix}SET_CAMPAIGN_DIALOG_EDIT_MODE`)
export const changeNewContentDialogView = createAction(`${prefix}CHANGE_NEW_CONTENT_DIALOG_VIEW`)

export const openBlockedContentEditDialog = createAction(`${prefix}OPEN_BLOCKED_CONTENT_EDIT_DIALOG`)
export const closeBlockedContentEditDialog = createAction(`${prefix}CLOSE_BLOCKED_CONTENT_EDIT_DIALOG`)

export const emailEditorLoadingStart = createAction(`${prefix}EMAIL_EDITOR_LOADING_START`)
export const emailEditorLoadingStop = createAction(`${prefix}EMAIL_EDITOR_LOADING_STOP`)

export const uploadEditorImageStart = createAction(`${prefix}UPLOAD_EDITOR_IMAGE_START`)
export const uploadEditorImageSuccess = createAction(`${prefix}UPLOAD_EDITOR_IMAGE_SUCCESS`)
export const uploadEditorImageError = createAction(`${prefix}UPLOAD_EDITOR_IMAGE_ERROR`)

export const resetUploadedImageUrl = createAction(`${prefix}RESET_UPLOADED_IMAGE_URL`)

export const saveCampaignStart = createAction(`${prefix}SAVE_CAMPAIGN_START`)
export const saveCampaignSuccess = createAction(`${prefix}SAVE_CAMPAIGN_SUCCESS`)
export const saveCampaignError = createAction(`${prefix}SAVE_CAMPAIGN_ERROR`)

export const deleteContentStart = createAction(`${prefix}DELETE_CONTENT_START`)
export const deleteContentSuccess = createAction(`${prefix}DELETE_CONTENT_SUCCESS`)
export const deleteContentError = createAction(`${prefix}DELETE_CONTENT_ERROR`)

export const deleteCampaignStart = createAction(`${prefix}DELETE_CAMPAIGN_START`)
export const deleteCampaignSuccess = createAction(`${prefix}DELETE_CAMPAIGN_SUCCESS`)
export const deleteCampaignError = createAction(`${prefix}DELETE_CAMPAIGN_ERROR`)

export const setAssignees = createAction(`${prefix}SET_ASSIGNEES`)
export const setCampaigns = createAction(`${prefix}SET_CAMPAIGNS`)
export const setUpdatedContents = createAction(`${prefix}SET_UPDATED_CONTENTS`)
export const setUpdatedCampaignsContents = createAction(`${prefix}SET_UPDATED_CAMPAIGNS_CONTENTS`)

export const setSelectedQueryType = createAction(`${prefix}SET_SELECTED_QUERY_TYPE`)
export const uiEnteredQuery = createAction(`${prefix}ENTERED_QUERY`)

export const uiRemoveFilter = createAction(`${prefix}REMOVE_FILTER`)
export const uiInvertFilter = createAction(`${prefix}INVERT_FILTER`)
export const uiUpdateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const toggleActiveFilters = createAction(`${prefix}TOGGLE_ACTIVE_FILTERS`)

export const switchToContactManagement = createAction(`${prefix}SWITCH_TO_CONTACT_MANAGEMENT`)
export const switchToEmailTemplatesLibrary = createAction(`${prefix}SWITCH_TO_EMAIL_TEMPLATE_LIBRARY`)

export const switchToSearchPool = createAction(`${prefix}SWITCH_TO_SEARCH_POOL`)
export const toggleEditorAiMode = createAction(`${prefix}TOGGLE_EDITOR_AI_MODE`)
export const setAiSelectedText = createAction(`${prefix}SET_AI_SELECTED_TEXT`)

export const setMediaFilesInitialFetch = createAction(`${prefix}SET_MEDIA_FILES_INITIAL_FETCH`)

export const openTestMailDialog = createAction(`${prefix}OPEN_TEST_MAIL_DIALOG`)
export const closeTestMailDialog = createAction(`${prefix}CLOSE_TEST_MAIL_DIALOG`)

export const linkedInCompanySearchStart = createAction(`${prefix}LINKED_IN_COMPANY_SEARCH_START`)
export const linkedInCompanySearchSuccess = createAction(`${prefix}LINKED_IN_COMPANY_SEARCH_SUCCESS`)
export const linkedInCompanySearchError = createAction(`${prefix}LINKED_IN_COMPANY_SEARCH_ERROR`)

export const openContentNotesDialog = createAction(`${prefix}OPEN_CONTENT_NOTES_DIALOG`)
export const closeContentNotesDialog = createAction(`${prefix}CLOSE_CONTENT_NOTES_DIALOG`)

export const openNewContent = createAction(`${prefix}OPEN_NEW_CONTENT`)

export const changeContentTabView = createAction(`${prefix}CHANGE_CONTENT_TAB_VIEW`)

export const changeContentEditTabView = createAction(`${prefix}CHANGE_CONTENT_EDIT_TAB_VIEW`)

export const changeContentsCampaignsTabView = createAction(`${prefix}CHANGE_CONTENTS_CAMPAIGNS_TAB_VIEW`)

export const openClosedCampaignDialog = createAction(`${prefix}OPEN_CLOSED_CAMPAIGN_DIALOG`)
export const closeClosedCampaignDialog = createAction(`${prefix}CLOSE_CLOSED_CAMPAIGN_DIALOG`)
export const setRecipientBrowserOpen = createAction(`${prefix}SET_RECIPIENT_BROWSER_OPEN`)

export const changeAttachmentsMediaType = createAction(`${prefix}CHANGE_ATTACHMENTS_MEDIA_TYPE`)

export const openAttachmentsDeleteDialog = createAction(`${prefix}OPEN_ATTACHMENTS_DELETE_DIALOG`)
export const closeAttachmentsDeleteDialog = createAction(`${prefix}CLOSE_ATTACHMENTS_DELETE_DIALOG`)

export const openAttachmentsImagePreviewDialog = createAction(`${prefix}OPEN_ATTACHMENTS_IMAGE_PREVIEW_DIALOG`)
export const closeAttachmentsImagePreviewDialog = createAction(`${prefix}CLOSE_ATTACHMENTS_IMAGE_PREVIEW_DIALOG`)

export const setContentIsPublishable = createAction(`${prefix}SET_CONTENT_IS_PUBLISHABLE`)

export const openTemplateConfirmDialog = createAction(`${prefix}OPEN_TEMPLATE_CONFIRM_DIALOG`)
export const closeTemplateConfirmDialog = createAction(`${prefix}CLOSE_TEMPLATE_CONFIRM_DIALOG`)

export const openTemplatePreviewDialog = createAction(`${prefix}OPEN_TEMPLATE_PREVIEW_DIALOG`)
export const closeTemplatePreviewDialog = createAction(`${prefix}CLOSE_TEMPLATE_PREVIEW_DIALOG`)

export const emailEditorForceLoadDesignStart = createAction(`${prefix}EMAIL_EDITOR_FORCE_LOAD_DESIGN_START`)
export const emailEditorForceLoadDesignStop = createAction(`${prefix}EMAIL_EDITOR_FORCE_LOAD_DESIGN_STOP`)

export const openOtsWelcomeDialog = createAction(`${prefix}OPEN_OTS_WELCOME_DIALOG`)
export const closeOtsWelcomeDialog = createAction(`${prefix}CLOSE_OTS_WELCOME_DIALOG`)

export const closeOtsSuccessDialog = createAction(`${prefix}CLOSE_OTS_SUCCESS_DIALOG`)
export const closeOtsErrorDialog = createAction(`${prefix}CLOSE_OTS_ERROR_DIALOG`)

export const openRecipientsClearAllConfirmDialog = createAction(`${prefix}OPEN_RECIPIENTS_CLEAR_ALL_CONFIRM_DIALOG`)
export const closeRecipientsClearAllConfirmDialog = createAction(`${prefix}CLOSE_RECIPIENTS_CLEAR_ALL_CONFIRM_DIALOG`)

// FILTER
prefix = `${defaultPrefix}FILTER/`
export const addSearchQuery = createAction(`${prefix}ADD_SEARCH_QUERY`)
export const removeFilter = createAction(`${prefix}REMOVE_FILTER`)
export const invertFilter = createAction(`${prefix}INVERT_FILTER`)
export const updateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const resetFilters = createAction(`${prefix}RESET_FILTERS`)
export const setPage = createAction(`${prefix}SET_PAGE`)
export const setLimit = createAction(`${prefix}SET_LIMIT`)
export const setSortBy = createAction(`${prefix}SET_SORT_BY`)
export const incrementMediaFilesPage = createAction(`${prefix}INCREMENT_MEDIA_FILES_PAGE`)
export const setContentFilters = createAction(`${prefix}SET_CONTENT_FILTERS`)
export const setCampaignFilters = createAction(`${prefix}SET_CAMPAIGN_FILTERS`)
export const setKpiStatisticsFilters = createAction(`${prefix}SET_KPI_STATISTICS_FILTERS`)
export const setRecipientBrowserQuery = createAction(`${prefix}SET_RECIPIENT_BROWSER_QUERY`)

// CALENDAR
prefix = `${defaultPrefix}CALENDAR/`

export const setCalendarDate = createAction(`${prefix}SET_CALENDAR_DATE`)
export const setCalendarSelectedCampaignId = createAction(`${prefix}SET_CALENDAR_SELECTED_CAMPAIGN_ID`)
export const setCalendarSelectedDay = createAction(`${prefix}SET_CALENDAR_SELECTED_DAY`)

export const fetchCalendarEventsStart = createAction(`${prefix}FETCH_CALENDAR_EVENTS_START`)
export const fetchCalendarEventsSuccess = createAction(`${prefix}FETCH_CALENDAR_EVENTS_SUCCESS`)
export const fetchCalendarEventsError = createAction(`${prefix}FETCH_CALENDAR_EVENTS_ERROR`)

export const openCalendarDialog = createAction(`${prefix}OPEN_CALENDAR_DIALOG`)
export const closeCalendarDialog = createAction(`${prefix}CLOSE_CALENDAR_DIALOG`)

export const sortContents = createAction(`${prefix}SORT_CONTENTS`)
export const sortCampaigns = createAction(`${prefix}SORT_CAMPAIGNS`)

export const onCalendarContentClick = createAction(`${prefix}ON_CALENDAR_CONTENT_CLICK`)
