import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { Box, CircularProgress } from '@mui/material'

/* Caution: these components are still in the old directory */
import LinkedInEditor from 'containers/content_desk/content_edit_dialog/linked_in_editor/LinkedInEditor'
import FacebookEditor from 'containers/content_desk/content_edit_dialog/facebook_editor/FacebookEditor'

import EmailEditor from 'containers/content_desk_new/contents/content_edit_dialog/editor/EmailEditor'

const useStyles = makeStyles()({
  container: {
    padding: '40px 20px 0px 20px'
  },
  hidden: {
    position: 'absolute !important',
    left: '-9999px !important',
    top: '-9999px !important'
  },
  editorContainer: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh'
  }
})

const Editor = ({
  emailEditorLoading,
  content,
  onChange
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}> {
      emailEditorLoading && (
        <div className={classes.circularLoader}>
          <CircularProgress
            variant="indeterminate"
            size={70}
          />
        </div>
      )
    }
      <div
        className={
          emailEditorLoading
            ? classes.hidden
            : classes.editorContainer
        }
      >
        {content.getIn(['type', 'id']) === 'email' && (
          <EmailEditor
            moduleScope="contents"
            editorHeight="580px"
            data={content}
            onChange={onChange}
          />
        )}
        {content.getIn(['type', 'id']) === 'linkedin' && <LinkedInEditor />}
        {content.getIn(['type', 'id']) === 'facebook' && <FacebookEditor />}
      </div>
    </Box>
  )
}

Editor.propTypes = {
  emailEditorLoading: PropTypes.bool.isRequired,
  content: PropTypes.instanceOf(Map).isRequired,

  onChange: PropTypes.func.isRequired
}

export default Editor
