import { put, takeEvery, all, call, select } from 'redux-saga/effects'

import * as Actions from 'actions/administration'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* searchAllowedDomains() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const name = yield select(Selectors.getAllowedDomainsQuickSearchQuery)
    const page = yield select(Selectors.getAllowedDomainsPage)
    const limit = yield select(Selectors.getAllowedDomainsLimit)

    const body = {
      filter: { name },
      page,
      limit
    }

    const result = yield call(Api.searchAllowedDomains, body)

    yield put(Actions.setAllowedDomains(result))
    yield put(Actions.searchAllowedDomainsSuccess())
  } catch (error) {
    yield put(Actions.searchAllowedDomainsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* watchSearchAllowedDomainsStart() {
  yield takeEvery(Actions.searchAllowedDomainsStart, searchAllowedDomains)
}

export default function* searchSaga() {
  yield all([
    watchSearchAllowedDomainsStart()
  ])
}
