import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/administration'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  ccdAllowedDomains: {
    quickSearchQuery: '',
    newAllowedDomain: '',
    requestRunning: false,
    editMode: false,
    deleteMode: false
  }
})

export default handleActions({
  [Actions.setAllowedDomainsQuickSearchQuery]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'quickSearchQuery'], payload),
  [Actions.setNewAllowedDomain]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'newAllowedDomain'], payload),
  [Actions.toggleAllowedDomainsEditMode]: state => state
    .setIn(['ccdAllowedDomains', 'editMode'], !state.getIn(['ccdAllowedDomains', 'editMode'])),
  [Actions.toggleAllowedDomainsDeleteMode]: state => state
    .setIn(['ccdAllowedDomains', 'deleteMode'], !state.getIn(['ccdAllowedDomains', 'deleteMode'])),
  [combineActions(
    Actions.searchAllowedDomainsStart,
    Actions.createAllowedDomainStart,
    Actions.updateAllowedDomainStart,
    Actions.deleteAllowedDomainStart
  )]: state => state.setIn(['ccdAllowedDomains', 'requestRunning'], true),
  [Actions.createAllowedDomainSuccess]: state => state.setIn(['ccdAllowedDomains', 'newAllowedDomain'], ''),
  [combineActions(
    Actions.searchAllowedDomainsSuccess,
    Actions.searchAllowedDomainsError,
    Actions.createAllowedDomainSuccess,
    Actions.createAllowedDomainError,
    Actions.updateAllowedDomainSuccess,
    Actions.updateAllowedDomainError,
    Actions.deleteAllowedDomainSuccess,
    Actions.deleteAllowedDomainError
  )]: state => state
    .setIn(['ccdAllowedDomains', 'requestRunning'], false)
    .setIn(['ccdAllowedDomains', 'editMode'], false)
    .setIn(['ccdAllowedDomains', 'deleteMode'], false),
  [AppActions.resetState]: () => initialState
}, initialState)
