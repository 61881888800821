import React from 'react'
import useI18n from 'hooks/useI18n'
import { PropTypes } from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import TemplatesWrapper from 'containers/email_templates_library/TemplatesWrapper'
import TemplatesEditorDialog from 'containers/email_templates_library/TemplatesEditorDialog'
import CenterMessage from 'containers/CenterMessage'
import AppBar from 'containers/email_templates_library/AppBar'

const useStyles = makeStyles()({
  templatesEditorContainer: {
    padding: '2em 2em 0em 2em'
  },
  centerMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh'
  },
  content: {
    maxWidth: 800
  }
})

export default function EmailTemplatesLibrary({ isDomainVerified }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (!isDomainVerified) {
    return (
      <>
        <AppBar />
        <div className={classes.centerMessageContainer}>
          <CenterMessage
            contentClass={classes.content}
            message={i18n.get('account_not_set_up_message')}
          />
        </div>
      </>
    )
  }

  return (
    <div className={classes.templatesEditorContainer}>
      <AppBar />
      <TemplatesWrapper />
      <TemplatesEditorDialog />
    </div>
  )
}

EmailTemplatesLibrary.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired
}
