/* eslint-disable import/named */
import { createAction } from 'redux-actions'

const defaultPrefix = 'ADMINISTRATION'
let prefix = defaultPrefix

// CD_SETTINGS/ALLOWED_DOMAINS DATA
prefix = `${defaultPrefix}/CD_SETTINGS/ALLOWED_DOMAINS/DATA/`

export const setAllowedDomains = createAction(`${prefix}SET_ALLOWED_DOMAINS`)
export const setAllowedDomainFormData = createAction(`${prefix}SET_FORM_DATA`)

export const createAllowedDomainStart = createAction(`${prefix}CREATE_START`)
export const createAllowedDomainSuccess = createAction(`${prefix}CREATE_SUCCESS`)
export const createAllowedDomainError = createAction(`${prefix}CREATE_ERROR`)

export const deleteAllowedDomainStart = createAction(`${prefix}DELETE_START`)
export const deleteAllowedDomainSuccess = createAction(`${prefix}DELETE_SUCCESS`)
export const deleteAllowedDomainError = createAction(`${prefix}DELETE_ERROR`)

export const updateAllowedDomainStart = createAction(`${prefix}UPDATE_START`)
export const updateAllowedDomainSuccess = createAction(`${prefix}UPDATE_SUCCESS`)
export const updateAllowedDomainError = createAction(`${prefix}UPDATE_ERROR`)

export const searchAllowedDomainsStart = createAction(`${prefix}SEARCH_START`)
export const searchAllowedDomainsSuccess = createAction(`${prefix}SEARCH_SUCCESS`)
export const searchAllowedDomainsError = createAction(`${prefix}SEARCH_ERROR`)

// CD_SETTINGS/ALLOWED_DOMAINS UI
prefix = `${defaultPrefix}/CD_SETTINGS/ALLOWED_DOMAINS/UI/`
export const setAllowedDomainsQuickSearchQuery = createAction(`${prefix}SET_QUICK_SEARCH_QUERY`)
export const setNewAllowedDomain = createAction(`${prefix}SET_NEW_ALLOWED_DOMAIN`)
export const toggleAllowedDomainsEditMode = createAction(`${prefix}TOGGLE_EDIT_MODE`)
export const toggleAllowedDomainsDeleteMode = createAction(`${prefix}TOGGLE_DELETE_MODE`)

// CD_SETTINGS/ALLOWED_DOMAINS FILTER
prefix = `${defaultPrefix}CD_SETTINGS/ALLOWED_DOMAINS/FILTER/`
export const setAllowedDomainsPage = createAction(`${prefix}SET_PAGE`)

