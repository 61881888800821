import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, List } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Autocomplete as MuiAutocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'

import { Input } from 'containers/themed'

import CampaignSelection from 'containers/content_desk_new/contents/content_edit_dialog/CampaignSelection'
import ClosedCampaignDialog from 'containers/content_desk_new/contents/content_edit_dialog/settings/ClosedCampaignDialog'

const useStyles = makeStyles()({
  container: {
    padding: '40px 20px 0px 20px'
  },
  header: {
    marginBottom: 20
  },
  formContainer: {
    marginBottom: 40
  },
  formField: {
    marginBottom: 20
  }
})

const mapDomainsToOptions = domains => domains.map(d => ({
  value: d.get('name'),
  label: '@'.concat(d.get('name'))
})).toJS()

const Settings = ({
  config,
  defaultDomains,
  contentFormData,
  emailEditorLoading,
  fetchDistributionLists,
  onChange
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const title = contentFormData.get('title')
  const senderName = contentFormData.get('senderName') || ''
  const subject = contentFormData.get('subject') || ''
  const preHeader = contentFormData.get('preHeader') || ''
  const [senderEmailLocal, setSenderEmailLocal] = useState((contentFormData.get('senderMail') || '').split('@')[0])
  const [senderEmailDomain, setSenderEmailDomain] = useState((contentFormData.get('senderMail') || '').split('@')[1])

  useEffect(() => {
    fetchDistributionLists()
  }, [])

  const domainsOptions = defaultDomains.toJS()
    .map(o => ({ value: o.domain, label: '@'.concat(o.domain) }))
    .concat(mapDomainsToOptions(config.get('ccdAllowedDomains') || List()))

  const handleMailChange = value => {
    setSenderEmailLocal(value)

    if (value === '') {
      onChange({ senderMail: '' })
    } else {
      onChange({ senderMail: value.concat('@').concat(senderEmailDomain) })
    }
  }

  const handleSenderEmailDomainChange = value => {
    setSenderEmailDomain(value)

    if (senderEmailLocal === '') {
      onChange({ senderMail: '' })
    } else {
      onChange({ senderMail: senderEmailLocal.concat('@').concat(value) })
    }
  }

  const basicInformation = (
    <Box className={classes.formContainer}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.header}
      >
        {i18n.get('info')}
      </Typography>

      <TextField
        className={classes.formField}
        fullWidth
        required
        error={!title}
        helperText={!title && `${i18n.get('internal_title')} ${i18n.get('is_required')}`}
        label={i18n.get('internal_title')}
        name="title"
        value={title}
        onChange={e => onChange({ title: e.target.value })}
      />

      <CampaignSelection />
    </Box>
  )

  const senderDetails = (
    <Box className={classes.formContainer}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.header}
      >
        {i18n.get('sender_details')}
      </Typography>

      <TextField
        className={classes.formField}
        fullWidth
        label={i18n.get('sender_name')}
        name="senderName"
        value={senderName}
        onChange={e => onChange({ senderName: e.target.value })}
      />

      <Input
        fullWidth
        required
        error={!senderEmailLocal}
        helperText={!senderEmailLocal && `${i18n.get('sender_email')} ${i18n.get('is_required')}`}
        className={classes.formField}
        type="text"
        value={senderEmailLocal}
        label={i18n.get('sender_email')}
        onChange={event => handleMailChange(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ width: '100%' }}
            >
              <MuiAutocomplete
                disablePortal
                disableClearable
                size="small"
                options={domainsOptions}
                value={domainsOptions.find(option => option.value === senderEmailDomain) || domainsOptions[0]}
                onChange={(_event, option) => handleSenderEmailDomainChange(option.value)}
                sx={{ width: '100%' }}
                renderInput={params => (
                  <TextField {...params} />
                )}
              />
              <Tooltip
                title={(
                  <Box dangerouslySetInnerHTML={{
                    __html: i18n.get('ccd_config_sender_email_tooltip', { email: 'customerservice@pressrelations.de' })
                  }}
                  />
                )}
                placement="bottom-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [15, 10]
                      }
                    }
                  ]
                }}
              >
                <InfoIcon sx={{ marginLeft: '10px' }} />
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )

  const emailSubject = (
    <Box className={classes.formContainer}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.header}
      >
        {i18n.get('email_subject')}
      </Typography>

      <TextField
        className={classes.formField}
        required
        error={!subject}
        helperText={!subject && `${i18n.get('email_subject')} ${i18n.get('is_required')}`}
        fullWidth
        label={i18n.get('subject')}
        name="subject"
        value={subject}
        onChange={e => onChange({ subject: e.target.value })}
      />

      <TextField
        disabled={emailEditorLoading}
        className={classes.formField}
        fullWidth
        label={i18n.get('email_pre_header')}
        name="preHeader"
        value={preHeader}
        onChange={e => onChange({ preHeader: e.target.value })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {emailEditorLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  size={20}
                />
              ) : (
                <Tooltip
                  title={i18n.get('email_pre_header_info')}
                  placement="bottom-end"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [15, 10]
                        }
                      }
                    ]
                  }}
                >
                  <InfoIcon />
                </Tooltip>
              )}
            </InputAdornment>
          )
        }}
      />
    </Box>
  )

  return (
    <>
      <Box
        className={classes.container}
      >
        {basicInformation}
        {senderDetails}
        {emailSubject}
      </Box>
      <ClosedCampaignDialog />
    </>
  )
}

Settings.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  defaultDomains: PropTypes.instanceOf(List).isRequired,
  emailEditorLoading: PropTypes.bool.isRequired,

  fetchDistributionLists: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Settings
