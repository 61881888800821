import { put, takeEvery, all, call, select } from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as Actions from 'actions/administration'
import * as ConfigActions from 'actions/config'
import * as AppActions from 'actions/app'

export function* createAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const name = yield select(Selectors.getAllowedDomainsNewAllowedDomain)

    const requestBody = {
      name
    }

    const result = yield call(Api.createAllowedDomain, requestBody)

    yield put(Actions.createAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())

    yield put(AppActions.showAppMessage({
      text: i18n.get('ccd_allowed_domain_created'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.createAllowedDomainError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* updateAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getAllowedDomainFormData)

    const requestBody = {
      name: formData.get('name')
    }

    const result = yield call(Api.updateAllowedDomain, formData.get('id'), requestBody)

    yield put(Actions.updateAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())

    yield put(AppActions.showAppMessage({
      text: i18n.get('ccd_allowed_domain_updated'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.updateAllowedDomainError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* deleteAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getAllowedDomainFormData)

    const result = yield call(Api.deleteAllowedDomain, formData.get('id'))

    yield put(Actions.deleteAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())

    yield put(AppActions.showAppMessage({
      text: i18n.get('ccd_allowed_domain_deleted'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deleteAllowedDomainError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* watchCreateAllowedDomainStart() {
  yield takeEvery(Actions.createAllowedDomainStart, createAllowedDomain)
}

export function* watchUpdateAllowedDomainStart() {
  yield takeEvery(Actions.updateAllowedDomainStart, updateAllowedDomain)
}

export function* watchDeleteAllowedDomainStart() {
  yield takeEvery(Actions.deleteAllowedDomainStart, deleteAllowedDomain)
}

export default function* mainSaga() {
  yield all([
    watchCreateAllowedDomainStart(),
    watchUpdateAllowedDomainStart(),
    watchDeleteAllowedDomainStart()
  ])
}
