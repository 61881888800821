import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, Map } from 'immutable'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'

import TimeZones from 'static/time_zones'

import { AlertTypes, Capabilities } from 'static/constants'

import MailAutocomplete from 'containers/MailAutocomplete'
import SmsAutocomplete from 'containers/SmsAutocomplete'
import { Select, Input, Autocomplete } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider as MuiDivider,
  Grid,
  Autocomplete as MuiAutocomplete,
  TextField,
  FormHelperText
} from '@mui/material'
import WebhookOption from './webhook_option'

const useStyles = makeStyles()({
  hint: {
    fontSize: '12pt !important'
  },
  alertDescription: {
    marginTop: -10,
    marginBottom: 0,
    fontSize: '12pt'
  },
  timezoneOptions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -20,
    marginBottom: -10,
    '& > div:last-child': {
      width: 300
    }
  },
  threshold: {
    width: 150
  },
  hiddenPopper: {
    display: 'none'
  }
})

const options = TimeZones.map(t => ({ value: t, label: t }))

const defaultLimit = 100
const limitOptions = [10, 25, 50, 75, 100, 150, 200, 250, 300].map(n => ({ value: n, label: n.toString() }))

const Divider = () => (
  <div>
    <br />
    <MuiDivider />
    <br />
  </div>
)

const SmsAutocompleteCapSafe = CapSafe(SmsAutocomplete, Capabilities.HAS_NEWS_POOL_SAVED_SEARCHES_SMS_ALERTING_FEATURE)
const SmsDivider = CapSafe(Divider, Capabilities.HAS_NEWS_POOL_SAVED_SEARCHES_SMS_ALERTING_FEATURE)

const locales = ['de', 'en']

const dayOptions = [1, 2, 3, 4, 5, 6, 0].map(day => ({
  value: day === 0 ? 7 : day,
  label: moment().day(day).format('dddd')
}))

const emptyAlertConfig = fromJS({
  type: AlertTypes.NONE,
  threshold: 0,
  times: [],
  days: [],
  timezone: 'Europe/Berlin',
  recipients: [],
  recipientsSms: [],
  webhooks: [],
  mailingListIds: [],
  sendNoResultsMail: false,
  groupBy: null
})

const validTimeValueFormat = value => value.match(/^\d\d:\d\d$/)

const filterTimeValues = values => values.filter(value => {
  const validFormat = validTimeValueFormat(value)

  if (validFormat) {
    let [hours, minutes] = value.split(':')

    hours = parseInt(hours, 10)
    minutes = parseInt(minutes, 10)

    return hours <= 24 && hours >= 0 && minutes <= 59 && minutes >= 0
  }

  return false
})

const renderWebhookOption = option => <WebhookOption url={option} />

export default function AlertOptions({
  i18n,
  savedSearch,
  mailingLists,
  locale,
  timezone,
  onChange
}) {
  const { classes } = useStyles()
  const getAlertConfig = () => emptyAlertConfig.merge(savedSearch.get('alertConfig', fromJS({})))
  const [timesInputValue, setTimesInputValue] = useState('')

  const handleChange = (key, value) => {
    onChange(savedSearch.set(key, value))
  }

  const handleAlertConfigChange = (key, value) => {
    const alertConfig = getAlertConfig().set(key, value)
    handleChange('alertConfig', alertConfig)
  }

  const handleTimeValueChange = value => {
    const currentTimes = getAlertConfig().get('times')
    const newTimes = currentTimes.push(value).toSet().toList()

    handleAlertConfigChange('times', newTimes)
  }

  const handleMailingListChange = values => {
    const mailingListIds = (values || fromJS([])).map(({ value }) => value)
    handleAlertConfigChange('mailingListIds', fromJS(mailingListIds))
  }

  const renderTimezoneDropdown = () => {
    const alertConfig = getAlertConfig()

    const selected = {
      label: alertConfig.get('timezone'),
      value: alertConfig.get('timezone')
    }

    return (
      <>
        <br />
        <br />
        <div className={classes.timezoneOptions}>
          <div>{i18n.get('alert_in_timezone')}:&nbsp;&nbsp;&nbsp;</div>
          <Autocomplete
            options={options}
            value={selected}
            onChange={({ value }) => handleAlertConfigChange('timezone', value)}
          />
        </div>
        <br />
      </>
    )
  }

  const renderInstantOptions = () => {
    const alertConfig = getAlertConfig()

    if ([AlertTypes.INSTANTLY, AlertTypes.HOURLY].indexOf(alertConfig.get('type')) === -1) {
      return null
    }

    return (
      <>
        <Divider />

        <div>{i18n.get('alert_type_interval')}:</div>
        <br />
        <RadioGroup
          value={alertConfig.get('type')}
          onChange={event => handleAlertConfigChange('type', event.target.value)}
        >
          <FormControlLabel
            label={i18n.get('instantly')}
            value={AlertTypes.INSTANTLY}
            control={<Radio />}
          />
          <FormControlLabel
            label={i18n.get('alert_hourly')}
            value={AlertTypes.HOURLY}
            control={<Radio />}
          />
        </RadioGroup>
      </>
    )
  }

  const renderTimeOptions = () => {
    const alertConfig = getAlertConfig()
    const times = alertConfig.get('times')
    const days = alertConfig.get('days') || fromJS([])

    if (alertConfig.get('type') !== AlertTypes.DAILY) {
      return null
    }

    const selectedTimezone = alertConfig.get('timezone')
    let info

    if (selectedTimezone !== timezone) {
      const formattedTimes = times.map(time => {
        const [hours, minutes] = time.split(':').map(t => parseInt(t, 10))

        return moment()
          .tz(selectedTimezone)
          .hours(hours)
          .minutes(minutes)
          .tz(timezone)
          .format('HH:mm')
      })
        .sort()
        .join(', ')

      info = (
        <div>
          {i18n.get('in_your_timezone', { timezone })}: {formattedTimes}
          <br />
          <br />
        </div>
      )
    }

    const limitReached = times.size >= 3

    const dayValues = days.map(day => {
      const found = dayOptions.find(d => d.value === day)

      if (found) {
        return found
      }

      return null
    }).filter(d => d).toJS()

    return (
      <>
        <Divider />

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <MuiAutocomplete
              multiple
              filterSelectedOptions
              options={dayOptions}
              getOptionLabel={option => option.label}
              value={dayValues}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, values) => {
                handleAlertConfigChange('days', fromJS(values.map(v => v.value)))
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={i18n.get('alert_days')}
                  placeholder={dayValues.length ? undefined : i18n.get('alert_days_placeholder')}
                />
              )}
            />
            <FormHelperText>
              {i18n.get('select_alert_days', { name: i18n.get('alert_type_daily') })}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <MuiAutocomplete
              multiple
              options={[]}
              getOptionLabel={option => option}
              value={times.toJS()}
              inputValue={timesInputValue}
              freeSolo={!limitReached}
              isOptionEqualToValue={(option, value) => option === value}
              classes={{
                popper: classes.hiddenPopper,
                popupIndicator: classes.hiddenPopper
              }}
              onChange={(event, values) => {
                const newValues = filterTimeValues(values)

                handleAlertConfigChange('times', fromJS(newValues))
              }}
              onInputChange={(_event, value) => {
                setTimesInputValue(value)

                if (validTimeValueFormat(value)) {
                  handleTimeValueChange(value)
                  setTimesInputValue('')
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  disabled={limitReached}
                  label={i18n.get('alert_times')}
                  placeholder={limitReached ? undefined : i18n.get('alert_times_placeholder')}
                />
              )}
            />
            <FormHelperText>
              {i18n.get('select_alert_times', { name: i18n.get('alert_type_daily') })}
            </FormHelperText>
          </Grid>
        </Grid>

        {renderTimezoneDropdown()}

        {info}
      </>
    )
  }

  const renderThresholdOptions = () => {
    const alertConfig = getAlertConfig()
    const threshold = alertConfig.get('threshold') || 0

    if (alertConfig.get('type') !== AlertTypes.DAILY_THRESHOLD) {
      return null
    }

    return (
      <>
        <Divider />

        <div className={classes.threshold}>
          <Input
            label={i18n.get('threshold')}
            type="number"
            value={threshold.toString()}
            onChange={event => handleAlertConfigChange('threshold', parseInt(event.target.value, 10))}
            variant="outlined"
          />
        </div>

        {renderTimezoneDropdown()}
      </>
    )
  }

  const renderMailOptions = () => {
    const alertConfig = getAlertConfig()
    const mailingListIds = alertConfig.get('mailingListIds')
    const hasSms = !alertConfig.get('recipientsSms').isEmpty()

    if (alertConfig.get('type') === AlertTypes.NONE) {
      return null
    }

    const recipients = alertConfig.get('recipients').toJS()
    const recipientsSms = alertConfig.get('recipientsSms').toJS()
    const mailingListsForAutocomplete = mailingLists
      .toList()
      .map(ml => ({ value: ml.get('id'), label: ml.get('name') }))
      .toJS()
    const selectedMailingLists = mailingLists
      .toList()
      .filter(m => mailingListIds.includes(m.get('id')))
      .map(ml => ({ value: ml.get('id'), label: ml.get('name') }))
      .toJS()
    const localeOptions = locales.map(l => ({
      value: l,
      label: i18n.get(`language_${l}`)
    }))

    const groupByOptions = [{
      value: 'ungrouped',
      label: i18n.get('ungrouped')
    },
    {
      value: 'media_review_codes',
      label: i18n.get('topics')
    }
    ]

    return (
      <>
        <Divider />

        {alertConfig.get('type') === AlertTypes.DAILY && (
          <p>
            <FormControlLabel
              label={i18n.get('send_no_results_mail')}
              control={(
                <Checkbox
                  checked={alertConfig.get('sendNoResultsMail')}
                  onChange={e => handleAlertConfigChange('sendNoResultsMail', e.target.checked)}
                />
              )}
            />
          </p>
        )}

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
            sm={5}
            md={4}
            lg={3}
            xl={3}
          >
            <Select
              label={i18n.get('alert_language')}
              options={localeOptions}
              value={savedSearch.get('locale') || locale}
              onChange={value => handleChange('locale', value.value)}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            xl={2}
          >
            <Select
              label={i18n.get('max_news_in_alert')}
              options={limitOptions}
              value={alertConfig.get('limit') || defaultLimit}
              onChange={value => handleAlertConfigChange('limit', value.value)}
              variant="outlined"
            />
          </Grid>
          {savedSearch.get('moduleName') === 'news' && (
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              lg={3}
              xl={3}
            >
              <Select
                label={i18n.get('group_by')}
                options={groupByOptions}
                value={alertConfig.get('groupBy') || 'ungrouped'}
                onChange={value => handleAlertConfigChange('groupBy', value.value)}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>

        <Divider />

        <Autocomplete
          placeholder=""
          label={i18n.get('select_mailing_list')}
          options={mailingListsForAutocomplete}
          value={selectedMailingLists}
          isMulti
          isClearable
          onChange={handleMailingListChange}
        />
        <p className={classes.hint} />
        <MailAutocomplete
          onChange={value => handleAlertConfigChange('recipients', fromJS(value))}
          value={recipients}
          variant="outlined"
        />

        <Divider />

        <MuiAutocomplete
          multiple
          options={[]}
          getOptionLabel={renderWebhookOption}
          value={alertConfig.get('webhooks').toJS()}
          freeSolo
          isOptionEqualToValue={(option, value) => option === value}
          classes={{
            popper: classes.hiddenPopper,
            popupIndicator: classes.hiddenPopper
          }}
          onChange={(event, values) => {
            handleAlertConfigChange('webhooks', fromJS(values))
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('webhooks')}
            />
          )}
        />
        <FormHelperText>
          {i18n.get('webhooks_help')}
        </FormHelperText>

        {hasSms && (
          <>
            <SmsDivider />

            <FormHelperText error>
              {i18n.get('feature_is_deprecated')}
            </FormHelperText>
            <SmsAutocompleteCapSafe
              disabled
              onChange={value => handleAlertConfigChange('recipientsSms', fromJS(value))}
              value={recipientsSms}
              variant="outlined"
            />
          </>
        )}
      </>
    )
  }

  const alertConfig = getAlertConfig()

  return (
    <div>
      <p className={classes.hint}>
        {i18n.get('alert_options')}
      </p>

      <br />

      <RadioGroup
        value={alertConfig.get('type')}
        onChange={event => handleAlertConfigChange('type', event.target.value)}
      >
        <FormControlLabel
          label={i18n.get('alert_type_none')}
          value={AlertTypes.NONE}
          control={<Radio />}
        />
        <br />
        <FormControlLabel
          label={i18n.get('alert_type_instantly')}
          value={alertConfig.get('type') === AlertTypes.HOURLY ? AlertTypes.HOURLY : AlertTypes.INSTANTLY}
          control={<Radio />}
        />
        <p className={classes.alertDescription}>{i18n.get('alert_type_instantly_description')}</p>
        <br />
        <FormControlLabel
          label={i18n.get('alert_type_daily')}
          value={AlertTypes.DAILY}
          control={<Radio />}
        />
        <p className={classes.alertDescription}>{i18n.get('alert_type_daily_description')}</p>
        <br />
        <FormControlLabel
          label={i18n.get('alert_type_daily_threshold')}
          value={AlertTypes.DAILY_THRESHOLD}
          control={<Radio />}
        />
        <p className={classes.alertDescription}>{i18n.get('alert_type_daily_threshold_description')}</p>
        <br />
      </RadioGroup>

      {renderInstantOptions()}
      {renderTimeOptions()}
      {renderThresholdOptions()}
      {renderMailOptions()}
    </div>
  )
}

AlertOptions.propTypes = {
  i18n: PropTypes.object.isRequired,
  savedSearch: PropTypes.instanceOf(Map).isRequired,
  mailingLists: PropTypes.instanceOf(Map).isRequired,
  locale: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired
}
