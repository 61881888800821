import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, List, fromJS } from 'immutable'
import useI18n from 'hooks/useI18n'
import { Waypoint } from 'react-waypoint'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Link,
  Button,
  useMediaQuery,
  Grid,
  LinearProgress
} from '@mui/material'

import { grey } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import FilterListIcon from '@mui/icons-material/FilterList'

import BlurredSection from 'components/themax/hidden_content/BlurredSection'

import EditorialPlansItem from 'containers/themax/editorial_plans/editorial_plans_item/EditorialPlansItem'
import { IconButton } from 'containers/themed'

import { shorten } from 'utils/string'

const useStyles = makeStyles()(theme => ({
  dialogContent: {
    display: 'flex',
    border: 'none',
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    overflow: 'hidden',
    minHeight: '85vh',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      overflow: 'auto'
    }
  },
  freeVersionContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  publicationInfoContainer: {
    width: '66%',
    height: '90vh',
    padding: '16px 24px',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      overflowY: 'visible',
      width: '100%'
    }
  },
  editorialPlansContainer: {
    flex: 1,
    backgroundColor: grey[200],
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      overflowY: 'unset'
    }
  },
  linkContainer: {
    display: 'flex',
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      wordBreak: 'break-word'
    }
  },
  link: {
    color: '#2A9BFD'
  },
  togglePublicationsLink: {
    color: '#2A9BFD',
    cursor: 'pointer'
  },
  publicationPdfContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  modeOfPublicationContainer: {
    display: 'flex',
    gap: '5px'
  },
  paidTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem'
    }
  }
}))

const DetailedViewDialog = ({
  view,
  open,
  editorialPlan,
  relatedEditorialPlans,
  relatedEditorialPlansTotal,
  hasPaidVersion,
  fetchMoreRequestRunning,
  onClose,
  onScrollBottom,
  setSelectedPublicationIds,
  applyFilters,
  changeTabView
}) => {
  const theme = useTheme()
  const { classes } = useStyles()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const publicationId = editorialPlan.getIn(['publication', 'id'])
  const publicationName = editorialPlan.getIn(['publication', 'name'])
  const editorialPlans = editorialPlan.getIn(['publication', 'editorialPlans']) || List()
  const moreEditorialPlans = view === 'media_profiles' ? relatedEditorialPlans : editorialPlans.unshift(editorialPlan)
  const [showAllPublications, setShowAllPublications] = useState(false)

  const { size } = relatedEditorialPlans

  const handleClose = () => {
    onClose()
  }

  const onEnterWaypoint = () => {
    if (size > 1 && size < relatedEditorialPlansTotal) {
      onScrollBottom()
    }
  }

  const handleDrillDown = () => {
    setSelectedPublicationIds(fromJS([{ id: publicationId, name: publicationName, label: publicationName, field: 'publication' }]))
    applyFilters()

    if (view !== 'editorial_plans') {
      changeTabView('editorial_plans')
    }

    handleClose()
  }

  const renderPaidView = () => {
    const publicationInfo = editorialPlan.get('publicationInfo')
    const publicationStreet = editorialPlan.getIn(['publication', 'street'])
    const publicationPostalCode = editorialPlan.getIn(['publication', 'postalCode'])
    const publicationCity = editorialPlan.getIn(['publication', 'city'])
    const publicationFile = editorialPlan.get('publicationFile')
    const editorialPhone = editorialPlan.get('editorialPhone')
    const editorialEmail = editorialPlan.get('editorialEmail')
    const editorialUrl = editorialPlan.get('editorialUrl')
    const advertisingEmail = editorialPlan.get('advertisingEmail')
    const advertisingPhone = editorialPlan.get('advertisingPhone')
    const frequencyOfPublication = editorialPlan.getIn(['publication', 'frequencyOfPublication'])
    const printrun = editorialPlan.getIn(['publication', 'printrun'])
    const publisherName = editorialPlan.getIn(['publication', 'publisher', 'name'])
    const publisherStreet = editorialPlan.getIn(['publication', 'publisher', 'street'])
    const publisherPostalCode = editorialPlan.getIn(['publication', 'publisher', 'postalCode'])
    const publisherCity = editorialPlan.getIn(['publication', 'publisher', 'city'])
    const publisherPhone = editorialPlan.getIn(['publication', 'publisher', 'phone'])
    const publisherPublicationsSize = (editorialPlan.getIn(['publication', 'publisher', 'publications']) || []).size
    let publisherPublications = editorialPlan.getIn(['publication', 'publisher', 'publications']) || []

    let showAllLink = (
      <Link
        className={classes.togglePublicationsLink}
        color="inherit"
        underline="hover"
        onClick={() => setShowAllPublications(false)}
      >
        ... {`${i18n.get('show_less')}`}
      </Link>
    )

    if (!showAllPublications) {
      publisherPublications = publisherPublications.slice(0, 10)
      showAllLink = (
        <Link
          className={classes.togglePublicationsLink}
          color="inherit"
          underline="hover"
          onClick={() => setShowAllPublications(true)}
        >
          ... {`${i18n.get('show_all')}`}
        </Link>
      )
    }

    return (
      <Grid
        container
        spacing={5}
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.publicationInfoContainer}
      >
        <Grid
          item
          xs={6}
        >
          <Typography fontWeight={500}>
            {`${i18n.get('editorial_office')}:`}
          </Typography>
          <Typography>
            {publicationName}
          </Typography>
          <Typography>
            {publicationStreet}
          </Typography>
          <Typography>
            {`${publicationPostalCode} ${publicationCity}`}
          </Typography>
          <Typography>
            {`${i18n.get('phone')}: ${editorialPhone || '-'}`}
          </Typography>
          <div className={classes.linkContainer}>
            <Typography>
              {`${i18n.get('website')}: `}
            </Typography>
            {editorialUrl ? (
              <Link
                className={classes.link}
                target="_blank"
                color="inherit"
                underline="hover"
                rel="noreferrer"
                href={editorialUrl}
                title={editorialUrl}
              >
                {shorten(editorialUrl, 40)}
              </Link>
            ) : '-'}
          </div>
          <div className={classes.linkContainer}>
            <Typography>
              {`${i18n.get('email')}: `}
            </Typography>
            {editorialEmail ? (
              <Link
                className={classes.link}
                color="inherit"
                underline="hover"
                href={`mailto:${editorialEmail}`}
                title={editorialEmail}
              >
                {shorten(editorialEmail, 40)}
              </Link>
            ) : '-'}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Typography fontWeight={500}>
            {`${i18n.get('advertising_contact')}:`}
          </Typography>
          <Typography>
            {`${i18n.get('phone')}: ${advertisingPhone || '-'}`}
          </Typography>
          <div className={classes.linkContainer}>
            <Typography>
              {`${i18n.get('email')}: `}
            </Typography>
            {advertisingEmail ? (
              <Link
                className={classes.link}
                color="inherit"
                underline="hover"
                href={`mailto:${advertisingEmail}`}
              >
                {advertisingEmail}
              </Link>
            ) : '-'}
          </div>
          <div><br /></div>
          {printrun > 0 && (
            <div className={classes.modeOfPublicationContainer}>
              <Typography fontWeight={500}>
                {`${i18n.get('printrun')}: `}
              </Typography>
              <Typography>
                {`${frequencyOfPublication} ${printrun} ${i18n.get('copies')}`}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Typography fontWeight={500}>
            {`${i18n.get('publisher')}:`}
          </Typography>
          <Typography>
            {publisherName}
          </Typography>
          <Typography>
            {publisherStreet}
          </Typography>
          <Typography>
            {`${publisherPostalCode} ${publisherCity}`}
          </Typography>
          <Typography>
            {`${i18n.get('phone')}: ${publisherPhone || '-'}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Typography fontWeight={500}>
            {`${i18n.get('publishing_programm')}:`}
          </Typography>
          {publisherPublications && publisherPublications.map((publication, index) => (
            <Typography key={index}>
              {publication.get('name')}
            </Typography>
          ))}
          {publisherPublicationsSize > 10 && (
            showAllLink
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography fontWeight={500}>
            {`${i18n.get('publication_profile')}:`}
          </Typography>
          <Typography>
            {publicationInfo}
          </Typography>
          <div><br /></div>
          <div className={classes.publicationPdfContainer}>
            <Typography fontWeight={500}>
              {`${i18n.get('media_information')}:`}
            </Typography>
            <Button
              target="_blank"
              rel="noreferrer"
              href={publicationFile}
              startIcon={<PictureAsPdfIcon />}
              download
            >
              {i18n.get('download_pdf')}
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderPaidTitle = () => (
    <Box className={classes.paidTitle}>
      {publicationName}
      <IconButton
        onClick={handleDrillDown}
        size="small"
      >
        <FilterListIcon sx={{ color: t => t.palette.primary.contrastText }} />
      </IconButton>
    </Box>
  )

  const renderFreeView = () => (
    <Grid
      container
      spacing={5}
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.publicationInfoContainer}
    >
      <Grid
        item
        xs={6}
      >
        <Typography fontWeight={500}>
          {`${i18n.get('editorial_office')}:`}
        </Typography>
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <div className={classes.linkContainer}>
          <Typography>
            {i18n.get('phone')}:
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
        <div className={classes.linkContainer}>
          <Typography>
            {`${i18n.get('website')}: `}
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
        <div className={classes.linkContainer}>
          <Typography>
            {`${i18n.get('email')}: `}
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography fontWeight={500}>
          {`${i18n.get('advertising_contact')}:`}
        </Typography>
        <div className={classes.linkContainer}>
          <Typography>
            {i18n.get('phone')}:
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
        <div className={classes.linkContainer}>
          <Typography>
            {`${i18n.get('email')}: `}
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
        <div><br /></div>
        <div className={classes.modeOfPublicationContainer}>
          <Typography fontWeight={500}>
            {`${i18n.get('printrun')}: `}
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="md"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography fontWeight={500}>
          {`${i18n.get('publisher')}:`}
        </Typography>
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <BlurredSection
          showLockIcon={false}
          size="sm"
        />
        <div className={classes.linkContainer}>
          <Typography>
            {i18n.get('phone')}:
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="sm"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography fontWeight={500}>
          {`${i18n.get('publishing_programm')}:`}
        </Typography>
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
        <BlurredSection
          showLockIcon={false}
          size="md"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography fontWeight={500}>
          {`${i18n.get('publication_profile')}:`}
        </Typography>
        <BlurredSection
          showLockIcon={false}
          size="lg"
        />
        <div><br /></div>
        <div className={classes.publicationPdfContainer}>
          <Typography fontWeight={500}>
            {`${i18n.get('media_information')}:`}
          </Typography>
          <BlurredSection
            showLockIcon={false}
            size="md"
          />
        </div>
      </Grid>
    </Grid>
  )

  return (
    <Dialog
      sx={{ zIndex: 200 }}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(2),
          backgroundColor: t => t.palette.primary.main,
          color: t => t.palette.primary.contrastText
        }}
      >
        {hasPaidVersion ? renderPaidTitle() : <BlurredSection />}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => t.palette.primary.contrastText
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContent}
      >
        {hasPaidVersion ? renderPaidView() : renderFreeView()}
        <Box className={classes.editorialPlansContainer}>
          {(open && editorialPlan) && (moreEditorialPlans
            .map((ep, index) => (
              <Box key={index}>
                <EditorialPlansItem
                  editorialPlan={ep}
                  isFirst={index === 0}
                  isShownInDetailedView
                />
              </Box>
            )))}
          {view === 'media_profiles' && (
            <>
              <Waypoint
                key="waypoint"
                onEnter={onEnterWaypoint}
              />
              {fetchMoreRequestRunning && (
                <Grid
                  key="loading"
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <LinearProgress
                    variant="indeterminate"
                  />
                </Grid>
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

DetailedViewDialog.propTypes = {
  view: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  editorialPlan: PropTypes.instanceOf(Map).isRequired,
  relatedEditorialPlans: PropTypes.instanceOf(List).isRequired,
  relatedEditorialPlansTotal: PropTypes.number.isRequired,
  hasPaidVersion: PropTypes.bool.isRequired,
  fetchMoreRequestRunning: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onScrollBottom: PropTypes.func.isRequired,
  setSelectedPublicationIds: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  changeTabView: PropTypes.func.isRequired
}

export default DetailedViewDialog
