import { createImmutableSelector } from 'utils/reselect'
import { List, fromJS } from 'immutable'
import moment from 'moment-timezone'

// CAMPAIGN_FORM

// DATA
export const getCDeskData = state => state.contentDesk.get('data')
export const getCDeskCampaigns = state => getCDeskData(state).get('ccdCampaigns')
export const getCDeskCampaignsContents = state => getCDeskCampaigns(state)
  .get('items')
  .reduce((acc, campaign) => acc.concat(campaign.get('ccdContents').toJS()), [])
export const getCDeskContents = state => getCDeskData(state).get('ccdContents')
export const getCDeskAssignees = state => getCDeskData(state).get('ccdAssignees')
export const getCDeskContentFormData = state => getCDeskData(state).get('contentFormData')
export const getCDeskContentFormDataRecipients = state => getCDeskData(state).get('contentFormData').get('recipients')
export const getCDeskCampaignFormData = state => getCDeskData(state).get('campaignFormData')
export const getCDeskCampaignDropdownOptions = state => getCDeskData(state).get('campaignDropdownOptions')
export const getCDeskContentDropdownOptions = state => getCDeskData(state).get('contentDropdownOptions')
export const getCDeskContactDropdownOptions = state => getCDeskData(state).get('contactDropdownOptions')
export const getCDeskDistributionListDropdownOptions = state => getCDeskData(state).get('distributionListDropdownOptions')
export const getCDeskStatistics = state => getCDeskData(state).get('ccdStatistics')
export const getCDeskKpiStatistics = state => getCDeskData(state).get('ccdKpiStatistics')
export const getCDeskSelectedContent = state => getCDeskData(state).get('selectedContent')
export const getCDeskHasContents = state => !getCDeskContents(state).get('items').isEmpty()
export const getCDeskHasCampaigns = state => !getCDeskCampaigns(state).get('items').isEmpty()
export const getCDeskUploadedMediaFiles = state => getCDeskData(state).get('uploadedMediaFiles')
export const getCDeskUploadedMediaFilesTotal = state => getCDeskData(state).get('uploadedMediaFilesTotal')
export const getCDeskAiSelectedText = state => getCDeskData(state).get('aiSelectedText')
export const getCDeskAiRequestResults = state => getCDeskData(state).get('aiRequestResults')
export const getCDeskUploadedMediaFilesTotalSize = state => getCDeskData(state).get('uploadedMediaFilesTotalSize')
export const getCDeskDistributionListSearchQuery = state => getCDeskData(state).get('distributionListSearchQuery')

// UI
export const getCDeskUi = state => state.contentDesk.get('ui')

export const getCDeskRequestRunning = state => getCDeskUi(state).get('requestRunning')
export const getCDeskShowNewContentDialog = state => getCDeskUi(state).get('showNewContentDialog')
export const getCDeskNewContentDialogSocialOnly = state => getCDeskUi(state).get('newContentDialogSocialOnly')
export const getCDeskShowEditContentDialog = state => getCDeskUi(state).get('showEditContentDialog')
export const getCDeskShowOldEditContentDialog = state => getCDeskUi(state).get('showOldEditContentDialog')
export const getCDeskShowViewContentDialog = state => getCDeskUi(state).get('showViewContentDialog')
export const getCDeskShowDeleteDialog = state => getCDeskUi(state).get('showDeleteDialog')
export const getCDeskShowBlockedContentEditDialog = state => getCDeskUi(state).get('showBlockedContentEditDialog')
export const getCDeskDeleteData = state => getCDeskUi(state).get('deleteData')
export const getCDeskShowNewCampaignDialog = state => getCDeskUi(state).get('showNewCampaignDialog')
export const getCDeskCampaignDialogEditMode = state => getCDeskUi(state).get('campaignDialogEditMode')
export const getCDeskNewDialogView = state => getCDeskUi(state).get('newDialogView')
export const getCDeskEmailEditorLoadingStatus = state => getCDeskUi(state).get('emailEditorLoading')
export const getCDeskContactDropdownOptionsLoading = state => getCDeskUi(state).get('contactDropdownOptionsLoading')
export const getCDeskCampaignDropdownOptionsLoading = state => getCDeskUi(state).get('campaignDropdownOptionsLoading')
export const getCDeskDistributionListDropdownOptionsLoading = state => getCDeskUi(state).get('distributionListDropdownOptionsLoading')
export const getCDeskContentIsLoading = state => getCDeskUi(state).get('isContentLoading')
export const getCDeskContentDialogEditMode = state => getCDeskUi(state).get('contentDialogEditMode')
export const getCDeskContentDialogCloneMode = state => getCDeskUi(state).get('contentDialogCloneMode')
export const getCDeskSelectedQueryType = state => getCDeskUi(state).get('selectedQueryType')
export const getCDesksearchBarLabel = state => getCDeskUi(state).get('searchBarLabel')
export const getCDeskSearchContentsRunning = state => getCDeskUi(state).get('searchContentsRunning')
export const getCDeskSearchCampaignsRunning = state => getCDeskUi(state).get('searchCampaignsRunning')
export const getCDeskContentUploadedImageUrl = state => getCDeskUi(state).get('uploadedImageUrl')
export const getCDeskActiveFiltersCollapsed = state => getCDeskUi(state).get('activeFiltersCollapsed')
export const getCDeskStatisticsRequestRunning = state => getCDeskUi(state).get('statisticsLoading')
export const getCDeskDuplicateError = state => getCDeskUi(state).get('duplicateError')
export const getCDeskShowScheduleDialog = state => getCDeskUi(state).get('showScheduleDialog')
export const getCDeskShowOldScheduleDialog = state => getCDeskUi(state).get('showOldScheduleDialog')
export const getCDeskShowPlanDialog = state => getCDeskUi(state).get('showPlanDialog')
export const getCDeskMediaFileUploading = state => getCDeskUi(state).get('mediaFileUploading')
export const getCDeskMediaFilesInitialFetch = state => getCDeskUi(state).get('mediaFilesInitialFetch')
export const getCDeskMediaFilesLoading = state => getCDeskUi(state).get('mediaFilesLoading')
export const getCDeskEditorAiMode = state => getCDeskUi(state).get('editorAiMode')
export const getCDeskEditorAiRequestRunning = state => getCDeskUi(state).get('editorAiRequestRunning')
export const getCDeskShowSaveTemplateDialog = state => getCDeskUi(state).get('showSaveTemplateDialog')
export const getSocialMediaLinkLoading = state => getCDeskUi(state).get('socialMediaLinkLoading')
export const getSocialMediaLinkBuildFailed = state => getCDeskUi(state).get('socialMediaLinkBuildFailed')
export const getCDeskShowTestMailDialog = state => getCDeskUi(state).get('showTestMailDialog')
export const getCDeskDownloadEmailEventsLoading = state => getCDeskUi(state).get('downloadEmailEventsLoading')
export const getCDeskLinkedInCompanySearchResults = state => getCDeskUi(state).get('linkedInCompanySearchResults')
export const getCDeskShowContentNotesDialog = state => getCDeskUi(state).get('showContentNotesDialog')
export const getCDeskContentTabView = state => getCDeskUi(state).get('contentTabView')
export const getCDeskContentEditTabView = state => getCDeskUi(state).get('contentEditTabView')
export const getCDeskContentsCampaignsTabView = state => getCDeskUi(state).get('contentsCampaignsTabView')
export const getCDeskLoadingKpiStatistics = state => getCDeskUi(state).get('loadingKpiStatistics')
export const getCDeskShowClosedCampaignDialog = state => getCDeskUi(state).get('showClosedCampaignDialog')
export const getCDeskAttachmentsMediaType = state => getCDeskUi(state).get('attachmentsMediaType')
export const getCDeskAttachmentsDeleteDialogOpen = state => getCDeskUi(state).get('attachmentsDeleteDialogOpen')
export const getCDeskAttachmentsDeleteMediaFileId = state => getCDeskUi(state).get('attachmentsDeleteMediaFileId')
export const getCDeskAttachmentsImagePreviewOpen = state => getCDeskUi(state).get('attachmentsImagePreviewDialogOpen')
export const getCDeskAttachmentsImagePreviewSrc = state => getCDeskUi(state).get('attachmentsImagePreviewSrc')
export const getCDeskRecipientBrowserOpen = state => getCDeskUi(state).get('recipientBrowserOpen')
export const getCDeskRecipientBrowserQuery = state => getCDeskUi(state).get('recipientBrowserQuery')
export const getCDeskContentIsPublishable = state => getCDeskUi(state).get('contentIsPublishable')
export const getCDeskTemplateConfirmDialogOpen = state => getCDeskUi(state).get('templateConfirmDialogOpen')
export const getCDeskTemplatePreviewDialogOpen = state => getCDeskUi(state).get('templatePreviewDialogOpen')
export const getCDeskTemplateDesign = state => getCDeskUi(state).get('templateDesign')
export const getCDeskEmailEditorForceLoadDesign = state => getCDeskUi(state).get('emailEditorForceLoadDesign')
export const getCDeskShowOtsWelcomeDialog = state => getCDeskUi(state).get('showOtsWelcomeDialog')
export const getCDeskShowOtsSuccessDialog = state => getCDeskUi(state).get('showOtsSuccessDialog')
export const getCDeskShowOtsErrorDialog = state => getCDeskUi(state).get('showOtsErrorDialog')
export const getCDeskShowRecipientsClearAllConfirmDialog = state => getCDeskUi(state).get('showRecipientsClearAllConfirmDialog')

// FILTERS
export const getCDeskFilters = state => state.contentDesk.get('filter')
export const getCDeskActiveFilters = createImmutableSelector(
  getCDeskFilters,
  filters => filters.filter((value, key) => List.isList(value) && key !== 'contentFilters')
)
export const getCDeskContentSearchQueries = state => getCDeskFilters(state).get('contentSearchQueries')
export const getCDeskCampaignSearchQueries = state => getCDeskFilters(state).get('campaignSearchQueries')
export const getCDeskCampaignPage = state => getCDeskFilters(state).get('campaignPage')
export const getCDeskCampaignLimit = state => getCDeskFilters(state).get('campaignLimit')
export const getCDeskCampaignSortBy = state => getCDeskFilters(state).get('campaignSortBy')
export const getCDeskCampaignSortOrder = state => getCDeskFilters(state).get('campaignSortOrder')
export const getCDeskContentPage = state => getCDeskFilters(state).get('contentPage')
export const getCDeskContentLimit = state => getCDeskFilters(state).get('contentLimit')
export const getCDeskContentSortBy = state => getCDeskFilters(state).get('contentSortBy')
export const getCDeskContentSortOrder = state => getCDeskFilters(state).get('contentSortOrder')
export const getCDeskMediaFilesPage = state => getCDeskFilters(state).get('mediaFilesPage')
export const getCDeskMediaFilesLimit = state => getCDeskFilters(state).get('mediaFilesLimit')
export const getCDeskCampaignFilters = state => getCDeskFilters(state).get('campaignFilters')
export const getCDeskContentFilters = state => getCDeskFilters(state).get('contentFilters')
export const getCDeskKpiStatisticsFilters = state => getCDeskFilters(state).get('kpiStatisticsFilters')

// CALENDAR
export const getCDeskCalendar = state => state.contentDesk.get('calendar')

// DATA
export const getCDeskCalendarData = state => getCDeskCalendar(state).get('data')
export const getCDeskCalendarContents = state => getCDeskCalendarData(state).get('contents')
export const getCDeskCalendarCampaigns = state => getCDeskCalendarData(state).get('campaigns')
export const getCDeskCalendarCampaignsContents = state => getCDeskCalendarCampaigns(state)
  .get('items')
  .reduce((acc, campaign) => acc.concat(campaign.get('ccdContents').toJS()), [])
export const getCDeskCalendarDate = state => getCDeskCalendarData(state).get('date')
export const getCDeskCalendarSelectedCampaignId = state => getCDeskCalendarData(state).get('selectedCampaignId')
export const getCDeskCalendarSelectedDay = state => getCDeskCalendarData(state).get('selectedDay')
export const getCDeskCalendarContentsSorting = state => getCDeskCalendarData(state).get('contentsSorting')
export const getCDeskCalendarCampaignsSorting = state => getCDeskCalendarData(state).get('campaignsSorting')

// UI
export const getCDeskCalendarUi = state => getCDeskCalendar(state).get('ui')
export const getCDeskShowCalendarDialog = state => getCDeskCalendarUi(state).get('showDialog')
export const getCDeskCalendarLoadingCampaignIds = state => getCDeskCalendarUi(state).get('loadingCampaignIds')
export const getCDeskCalendarLoadingContentIds = state => getCDeskCalendarUi(state).get('loadingContentIds')

// CALCULATED

export const getCDeskCalendarEvents = createImmutableSelector(
  getCDeskCalendarContents,
  getCDeskCalendarCampaigns,
  (contents, campaigns) => fromJS({
    total: contents.get('total') + campaigns.get('total'),
    items: contents.get('items').concat(
      campaigns.get('items')
    )
  })
)

export const getCDeskSelectedCalendarEvents = createImmutableSelector(
  getCDeskCalendarContents,
  getCDeskCalendarCampaigns,
  getCDeskCalendarSelectedDay,
  getCDeskCalendarSelectedCampaignId,
  (allContents, allCampaigns, selectedDay, selectedCampaignId) => {
    let contents = List()
    let campaigns = List()

    if (!selectedDay) {
      return fromJS({ contents, campaigns })
    }

    contents = allContents.get('items')
      .filter(event => {
        const scheduledForDate = event.get('scheduledFor')
        const plannedForDate = event.get('plannedFor')

        let date

        if (!scheduledForDate && plannedForDate) {
          date = moment(plannedForDate).toDate()
        } else {
          date = moment(scheduledForDate).toDate()
        }

        return moment(selectedDay).isSame(moment(date), 'day')
      })

    if (selectedCampaignId) {
      contents = contents.filter(event => event.getIn(['ccdCampaign', 'id']) === selectedCampaignId)
    }

    campaigns = allCampaigns.get('items')
      .filter(event => {
        const startDate = event.get('startDate')
        const endDate = event.get('endDate')

        return moment(selectedDay).isBetween(moment(startDate), moment(endDate), 'day', '[]')
      })

    return fromJS({
      contents,
      campaigns
    })
  }
)

export const getCDeskContentFormFilteredDistributionLists = createImmutableSelector(
  getCDeskDistributionListDropdownOptions,
  getCDeskDistributionListSearchQuery,
  (distributionLists, query) => {
    if (!query) {
      return distributionLists
    }

    return distributionLists.filter(dlist => dlist.get('name').toLowerCase().includes(query.toLowerCase()))
  }
)

export const getCDeskContentFormSelectedDistributionLists = createImmutableSelector(
  getCDeskContentFormDataRecipients,
  getCDeskDistributionListDropdownOptions,
  (allRecipients, distributionLists) => distributionLists.map(dlist => {
    const recipients = allRecipients.filter(r => r.get('distributionLists').some(dl => dl.get('id') === dlist.get('id')))

    return (fromJS({
      id: dlist.get('id'),
      name: dlist.get('name'),
      recipients,
      contacts: dlist.get('contacts'),
      partiallySelected: dlist.get('contacts').size > recipients.size
    }))
  })
    // for now we only want to show lists with all recipients
    .filter(dlist => !dlist.get('partiallySelected'))
    .filter(dlist => !dlist.get('recipients').isEmpty())
    // sorting is a bit hacky but it slightly prefers lists with less contacts (which are more 'fully' selected)
    .sortBy(dlist => -(dlist.get('recipients').size + dlist.get('recipients').size) / dlist.get('contacts').size)
)

export const getCDeskContentFormDataFilteredRecipients = createImmutableSelector(
  getCDeskContentFormDataRecipients,
  getCDeskRecipientBrowserQuery,
  (recipients, query) => {
    if (!query) {
      return recipients
    }

    return recipients.filter(recipient => {
      const contact = recipient.get('contact')

      const searchFields = fromJS([
        (contact.get('searchable') || ''),
        (contact.get('externalPublication') || ''),
        recipient.get('mail')
      ]).concat(recipient.get('distributionLists').map(dl => dl.get('name')))
        .map(value => value.toLowerCase()).toJS()

      return searchFields.some(value => value.includes(query.toLowerCase()))
    })
  }
)

