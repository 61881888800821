import { createAction } from 'redux-actions'

const prefix = 'CONFIG/'

export const configRequestError = createAction(`${prefix}CONFIG_REQUEST_ERROR`)
export const configRequestStart = createAction(`${prefix}CONFIG_REQUEST_START`)
export const configRequestSuccess = createAction(`${prefix}CONFIG_REQUEST_SUCCESS`)
export const resetConfigToDefault = createAction(`${prefix}RESET_CONFIG_TO_DEFAULT`)
export const resetColors = createAction(`${prefix}RESET_COLORS`)
export const setConfig = createAction(`${prefix}SET_CONFIG`)
export const updateConfig = createAction(`${prefix}UPDATE_CONFIG`)
export const setStatics = createAction(`${prefix}SET_STATICS`)
export const validateFilenameInConfig = createAction(`${prefix}VALIDATE_FILENAME_IN_CONFIG`)
export const setColors = createAction(`${prefix}SET_COLORS`)
export const setTheme = createAction(`${prefix}SET_THEME`)
export const setStartedWithDeepLink = createAction(`${prefix}SET_STARTED_WITH_DEEP_LINK`)

export const updateConfigRequestError = createAction(`${prefix}UPDATE_CONFIG_REQUEST_ERROR`)
export const updateConfigRequestStart = createAction(`${prefix}UPDATE_CONFIG_REQUEST_START`)
export const updateConfigRequestSuccess = createAction(`${prefix}UPDATE_CONFIG_REQUEST_SUCCESS`)

export const updateUserConfigRequestError = createAction(`${prefix}UPDATE_USER_CONFIG_REQUEST_ERROR`)
export const updateUserConfigRequestStart = createAction(`${prefix}UPDATE_USER_CONFIG_REQUEST_START`)
export const updateUserConfigRequestSuccess = createAction(`${prefix}UPDATE_USER_CONFIG_REQUEST_SUCCESS`)

export const createCDeskConfigRequestError = createAction(`${prefix}CREATE_CONTENT_DESK_CONFIG_REQUEST_ERROR`)
export const createCDeskConfigRequestStart = createAction(`${prefix}CREATE_CONTENT_DESK_CONFIG_REQUEST_START`)
export const createCDeskConfigRequestSuccess = createAction(`${prefix}CREATE_CONTENT_DESK_CONFIG_REQUEST_SUCCESS`)

export const fetchCDeskConfigRequestError = createAction(`${prefix}FETCH_CONTENT_DESK_CONFIG_REQUEST_ERROR`)
export const fetchCDeskConfigRequestStart = createAction(`${prefix}FETCH_CONTENT_DESK_CONFIG_REQUEST_START`)
export const fetchCDeskConfigRequestSuccess = createAction(`${prefix}FETCH_CONTENT_DESK_CONFIG_REQUEST_SUCCESS`)

export const updateCDeskConfigRequestError = createAction(`${prefix}UPDATE_CONTENT_DESK_CONFIG_REQUEST_ERROR`)
export const updateCDeskConfigRequestStart = createAction(`${prefix}UPDATE_CONTENT_DESK_CONFIG_REQUEST_START`)
export const updateCDeskConfigRequestSuccess = createAction(`${prefix}UPDATE_CONTENT_DESK_CONFIG_REQUEST_SUCCESS`)

export const verifyCDeskDomainRequestError = createAction(`${prefix}VERIFY_CONTENT_DESK_DOMAIN_REQUEST_ERROR`)
export const verifyCDeskDomainRequestStart = createAction(`${prefix}VERIFY_CONTENT_DESK_DOMAIN_REQUEST_START`)
export const verifyCDeskDomainRequestSuccess = createAction(`${prefix}VERIFY_CONTENT_DESK_DOMAIN_REQUEST_SUCCESS`)

export const refreshContentDeskPlanUsageAndLimits = createAction(`${prefix}REFRESH_CONTENT_DESK_PLAN_USAGE_AND_LIMITS`)

export const setCDeskConfig = createAction(`${prefix}SET_CONTENT_DESK_CONFIG`)
export const updateCDeskConfig = createAction(`${prefix}UPDATE_CONTENT_DESK_CONFIG`)

export const setNewsSavedSearchId = createAction(`${prefix}SET_NEWS_SAVED_SEARCH_ID`)
export const setAnalysisSavedSearchId = createAction(`${prefix}SET_ANALYSIS_SAVED_SEARCH_ID`)
export const setProfileMonitoringSavedSearchId = createAction(`${prefix}SET_PROFILE_MONITORING_SAVED_SEARCH_ID`)
export const setNewsPoolSavedSearchId = createAction(`${prefix}SET_NEWS_POOL_SAVED_SEARCH_ID`)
export const setDashboardId = createAction(`${prefix}SET_DASHBOARD_ID`)
export const setUserConfigField = createAction(`${prefix}SET_USER_CONFIG_FIELD`)
export const setGoogleAnalyticsEnabled = createAction(`${prefix}SET_GOOGLE_ANALYTICS_ENABLED`)
export const setGoogleAnalyticsDisabled = createAction(`${prefix}SET_GOOGLE_ANALYTICS_DISABLED`)
export const setYoutubeAnalyticsEnabled = createAction(`${prefix}SET_YOUTUBE_ANALYTICS_ENABLED`)
export const setYoutubeAnalyticsDisabled = createAction(`${prefix}SET_YOUTUBE_ANALYTICS_DISABLED`)
export const setFacebookAnalyticsEnabled = createAction(`${prefix}SET_FACEBOOK_ANALYTICS_ENABLED`)
export const setFacebookAnalyticsDisabled = createAction(`${prefix}SET_FACEBOOK_ANALYTICS_DISABLED`)
export const setLinkedInAnalyticsEnabled = createAction(`${prefix}SET_LINKED_IN_ANALYTICS_ENABLED`)
export const setLinkedInAnalyticsDisabled = createAction(`${prefix}SET_LINKED_IN_ANALYTICS_DISABLED`)
export const setDarkMode = createAction(`${prefix}SET_DARK_MODE`)
export const setPrefersDarkMode = createAction(`${prefix}SET_PREFERS_DARK_MODE`)

export const updatePaidFeatures = createAction(`${prefix}UPDATE_PAID_FEATURES`)

export const orderPaidVersionStart = createAction(`${prefix}ORDER_PAID_VERSION_START`)
export const orderPaidVersionSuccess = createAction(`${prefix}ORDER_PAID_VERSION_SUCCESS`)
export const orderPaidVersionError = createAction(`${prefix}ORDER_PAID_VERSION_ERROR`)
