// ADMINISTRATION
export const getAdministration = state => state.administration
export const getAdministrationData = state => getAdministration(state).get('data')
export const getAdministrationUi = state => getAdministration(state).get('ui')
export const getAdministrationFilter = state => getAdministration(state).get('filter')

// CONTENT DESK SETTINGS ALLOWED DOMAINS DATA
export const getAllowedDomains = state => getAdministrationData(state).get('ccdAllowedDomains')
export const getAllowedDomainFormData = state => getAdministrationData(state).get('ccdAllowedDomainFormData')

// CONTENT DESK SETTINGS ALLOWED DOMAINS UI
export const getAllowedDomainsUi = state => getAdministrationUi(state).get('ccdAllowedDomains')
export const getAllowedDomainsRequestRunning = state => getAllowedDomainsUi(state).get('requestRunning')
export const getAllowedDomainsQuickSearchQuery = state => getAllowedDomainsUi(state).get('quickSearchQuery')
export const getAllowedDomainsNewAllowedDomain = state => getAllowedDomainsUi(state).get('newAllowedDomain')
export const getAllowedDomainsEditMode = state => getAllowedDomainsUi(state).get('editMode')
export const getAllowedDomainsDeleteMode = state => getAllowedDomainsUi(state).get('deleteMode')

// CONTENT DESK SETTINGS ALLOWED DOMAINS FILTERS
export const getAllowedDomainsFilters = state => getAdministrationFilter(state).get('ccdAllowedDomains')
export const getAllowedDomainsPage = state => getAllowedDomainsFilters(state).get('page')
export const getAllowedDomainsLimit = state => getAllowedDomainsFilters(state).get('limit')

