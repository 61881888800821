import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { grey } from '@mui/material/colors'
import { Box, Grid } from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import NrxProgressBackdrop from 'components/nrx_progress_backdrop/NrxProgressBackdrop'

import AppBar from 'containers/content_desk_new/AppBar'
import ActiveFilterList from 'containers/content_desk_new/ActiveFilterList'
import CalendarWidget from 'containers/content_desk_new/CalendarWidget'
import CenterMessage from 'containers/CenterMessage'
import PrimaryNavigation from 'containers/content_desk_new/PrimaryNavigation'
import ContentHeader from 'containers/content_desk_new/ContentHeader'
import KpiStatistics from 'containers/content_desk_new/KpiStatistics'
import ContentsCampaignsOverview from 'containers/content_desk_new/contents_campaigns_overview/ContentsCamapaignsOverview'
import OtsWelcomeDialog from 'containers/content_desk_new/OtsWelcomeDialog'
import OtsSuccessDialog from 'containers/content_desk_new/OtsSuccessDialog'
import OtsErrorDialog from 'containers/content_desk_new/OtsErrorDialog'

const useStyles = makeStyles()(theme => ({
  backdrop: {
    zIndex: 10
  },
  container: {
    backgroundColor: grey[100],
    padding: '30px',
    [theme.breakpoints.only('xs')]: {
      padding: '15px'
    }
  },
  activeFilterListContainer: {
    marginBottom: 20
  },
  centerMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh'
  },
  content: {
    maxWidth: 800
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '& > span:first-of-type': {
      marginRight: 5
    }
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    [theme.breakpoints.only('xs')]: {
      rowGap: '10px'
    }
  }
}))

export default function ContentDesk({
  isDomainVerified,
  isContentLoading,
  contents,
  campaigns,
  calendarContents,
  calendarCampaigns,
  fetchKpi,
  search,
  setLimit,
  refreshContentsStatusStart,
  stopRefreshContentsStatus
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const device = useDevice()
  const mobile = device.get('mobile')

  if (!isDomainVerified) {
    return (
      <>
        <AppBar />
        <div className={classes.centerMessageContainer}>
          <CenterMessage
            contentClass={classes.content}
            message={i18n.get('account_not_set_up_message')}
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    fetchKpi()
    setLimit({ key: 'contentLimit', value: 10 })
    setLimit({ key: 'campaignLimit', value: 10 })
    search()
  }, [])

  useEffect(() => {
    refreshContentsStatusStart()

    return () => {
      stopRefreshContentsStatus()
    }
  }, [contents, campaigns, calendarContents, calendarCampaigns])

  return (
    <Box className={classes.container}>
      <NrxProgressBackdrop open={isContentLoading} />
      <AppBar />
      <Box className={classes.activeFilterListContainer}>
        <ActiveFilterList />
      </Box>
      <Grid
        container
        columnSpacing={3}
      >
        <Grid
          className={classes.leftColumn}
          item
          xs={12}
          lg={8}
          xl={8}
        >
          {!mobile && <PrimaryNavigation />}
          <ContentHeader />
          <KpiStatistics />
          <ContentsCampaignsOverview />
        </Grid>
        {!mobile && (
          <Grid
            item
            xs={12}
            lg={4}
            xl={4}
          >
            <CalendarWidget />
          </Grid>
        )}
      </Grid>
      <OtsWelcomeDialog />
      <OtsSuccessDialog />
      <OtsErrorDialog />
    </Box>
  )
}

ContentDesk.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  contents: PropTypes.instanceOf(Map).isRequired,
  campaigns: PropTypes.instanceOf(Map).isRequired,
  calendarContents: PropTypes.instanceOf(Map).isRequired,
  calendarCampaigns: PropTypes.instanceOf(Map).isRequired,

  fetchKpi: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  refreshContentsStatusStart: PropTypes.func.isRequired,
  stopRefreshContentsStatus: PropTypes.func.isRequired
}
